import { Link } from '@reach/router'
import { Alert, Descriptions, Table, Typography } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import update from 'react-addons-update'
import styled from 'styled-components'
import WeekSelector from '../../../../components/common/WeekSelector'
import { consoleReservationsInspect } from '../../../../logic/network'
import { formatCurrency, formatDate, stripePaymentIntentIdUrl } from '../../../../logic/utils'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

const StyledContainer = styled.div`
  text-align: center;
  padding: 0 24px 0 24px;
`

export default class ContainerReservations extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  static columns = [
    {
      title: 'Reservation ID',
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>,
      width: 300
    },
    {
      title: 'Bay',
      dataIndex: 'locationContainerBayLabel',
      key: 'locationContainerBayLabel',
      render: v => v ? v.split(' ')[1] : 'N/A'
    },
    {
      title: 'Start',
      dataIndex: 'range.start',
      key: 'start',
      render: v => formatDate(v),
      width: 230
    },
    {
      title: 'End',
      dataIndex: 'range.end',
      key: 'end',
      render: v => formatDate(v),
      width: 230
    }
  ]

  static renderExpandedRow(row) {
    return (
      <Descriptions bordered size='small' column={1}>
        {row.consoleUserId && <Descriptions.Item label='User ID'><Link to={`/infrastructure/juicer-app-users/${row.consoleUserId}`}><StyledCode code>{row.consoleUserId}</StyledCode></Link></Descriptions.Item>}
        {row.consolePartnerId && <Descriptions.Item label='Partner ID'>{row.consolePartnerId}</Descriptions.Item>}
        {row.price && <Descriptions.Item label='Price'>{formatCurrency(row.price, row.currency)}</Descriptions.Item>}
        {row.price && <Descriptions.Item label='Discount'>{row.discount ? `${row.discount}%` : 'None'}</Descriptions.Item>}
        {row.access && row.access.type === 'pinCode' && <Descriptions.Item label='Access'>Pin Code ({row.access.pinCode})</Descriptions.Item>}
        {row.stripePaymentIntentId && <Descriptions.Item label='Stripe Payment Intent ID'><a href={stripePaymentIntentIdUrl(row.consoleStripePaymentIntentId)}>{row.consoleStripePaymentIntentId}</a></Descriptions.Item>}
        <Descriptions.Item label='Created At'>{formatDate(row.consoleCreatedAt)}</Descriptions.Item>
      </Descriptions>
    )
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      yearWeek: moment()
    }
  }

  async loadData(location, yearWeek) {
    return (await consoleReservationsInspect(location.id, yearWeek)).reservations
  }

  handleChange(yearWeek) {
    this.setState(update(this.state, {
      yearWeek: { $set: yearWeek }
    }))
  }

  render() {
    const { location } = this.props
    const { yearWeek } = this.state

    return (
      <Loads fn={this.loadData}  context='infrastructure/locations/location/container-reservations' variables={[location, yearWeek]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            {!isRejected &&
              <Fragment>
                <StyledContainer>
                  <WeekSelector disabled={isPending} onChange={this.handleChange} value={yearWeek}/>
                  <br/>
                  <Table expandRowByClick={true} rowKey={r => r.id} columns={ContainerReservations.columns} dataSource={!isPending && !isReloading && isResolved && response ? response : []} expandedRowRender={ContainerReservations.renderExpandedRow} loading={isPending} pagination={false} size='middle' scroll={{y: 'calc(100vh - 19rem)'}}/>
                </StyledContainer>
              </Fragment>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}