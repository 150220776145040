import { Alert, Modal } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class GlobalError extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired
  }

  render() {
    return (
      <Modal transitionName='none' centered closable={false} footer={null} title='Charge Console' visible={true}>
        <Alert message={this.props.message} type='error'/>
      </Modal>
    )
  }
}