import { Divider, Typography } from 'antd'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

const StyledTitle = styled(Typography.Text)`
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
`

const StyledDivider = styled(Divider)`
  margin-top: 8px !important;
  padding-top 0 !important;
  margin-bottom: 24px !important;
  padding-bottom: 0 !important;
`

export default class SectionHeader extends Component {
  render() {
    const { children } = this.props

    return (
      <Fragment>
        <StyledTitle>{children}</StyledTitle>
        <StyledDivider/>
      </Fragment>
    )
  }
}