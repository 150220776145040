import axios from 'axios'
import moment from 'moment'
import { getToken } from './auth'
import { RequestError, UnauthorizedError, UnexpectedError } from './errors'
import config from './config'

export async function consoleAlertsInspect() {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/alertsInspect`,
        data: {}
    })
}

export async function consoleAuthenticationVerify(email) {
    return request({
        authenticated: true,
        errorMetadata: {
            email
        },
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/authenticationVerify`,
        data: {}
    })
}

export async function consoleInvoicesInspect(paid) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/invoicesInspect`,
        data: {
            paid
        }
    })
}

export async function consoleInvoicesSetPaid(partnerId, partnerEntityId, yearMonth) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/invoicesSetPaid`,
        data: {
            partnerId,
            partnerEntityId,
            yearMonth
        }
    })
}

export async function consoleDiagnosticsPerform(options) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/diagnosticsPerform`,
        data: options
    })
}

export async function consoleLocationInspect(locationId, options) {
    if (!options) {
        options = {}
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/locationInspect`,
        data: {
            locationId,
            ...options
        }
    })
}

export async function consoleLocationsInspect(options) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/locationsInspect`,
        data: options
    })
}

export async function consoleLocationUpdate(locationId, updates) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/locationUpdate`,
        data: {
            locationId,
            ...updates
        }
    })
}

export async function consolePartnerInspect(partnerId, options) {
    const data = options ? options : {}
    options.partnerId = partnerId

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/partnerInspect`,
        data: data
    })
}
export async function consolePartnersInspect() {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/partnersInspect`,
        data: {}
    })
}

export async function consolePartnersCreate(partnerId, partnerName, initialDashboardUserEmail) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersCreate`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersCreate`,
        data: {
            partnerId,
            partnerName,
            initialDashboardUserEmail
        }
    })
}
export async function consolePartnersEntityCreate(partnerId, entityName, countryCode, taxId, address) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersEntityCreate`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersEntityCreate`,
        data: {
            partnerId,
            entityName,
            countryCode,
            taxId,
            address
        }
    })
}

export async function consolePartnersDelete(partnerId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersDelete`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersDelete`,
        data: {
            partnerId,
        }
    })
}

export async function consolePartnersEntityDelete(partnerId, partnerEntityId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersEntityDelete`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersEntityDelete`,
        data: {
            partnerId,
            partnerEntityId,
        }
    })
}

export async function consolePrivateLocations(partnerId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/getPrivateLocations`,
        // url:  `http://0.0.0.0:3001/console-v3/getPrivateLocations`,
        data: {
            partnerId,
        }
    })
}

export async function consolePartnersAddPrivateLocations(partnerId, locations) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersAddPrivateLocations`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersAddPrivateLocations`,
        data: {
            partnerId,
            locations,
        }
    })
}

export async function consolePartnersDeletePrivateLocations(partnerId, locations) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/partnersDeletePrivateLocations`,
        // url:  `http://0.0.0.0:3001/console-v3/partnersDeletePrivateLocations`,
        data: {
            partnerId,
            locations,
        }
    })
}

export async function consoleGetLocations(options) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/getLocations`,
        data: options
    })
}

export async function consoleCreateLocation(options) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/createLocations`,
        // url:  `http://localhost:3001/console-v3/createLocations`,
        data: options
    })
}

export async function consoleDeleteLocation(locationId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/deleteLocations`,
        // url:  `http://0.0.0.0:3001/console-v3/deleteLocations`,
        data: {
            locationId,
        }
    })
}

export async function consoleGetBalenaDevices() {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/getBalenaDevices`,
        data: {}
    })
}


export async function consoleReservationsInspect(locationId, yearWeek) {
    if (moment.isMoment(yearWeek)) {
        yearWeek = `${yearWeek.isoWeekYear()}-${yearWeek.isoWeek()}`
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/reservationsInspect`,
        data: {
            locationId,
            yearWeek
        }
    })
}

export async function consoleSessionsInspect(endFrom, endTo, locationId, partnerId) {
    if (!moment.isMoment(endFrom)) {
        endFrom = moment(endFrom)
    }

    if (!moment.isMoment(endTo)) {
        endTo = moment(endTo)
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/sessionsInspect`,
        data: {
            endFrom,
            endTo,
            locationId,
            partnerId
        }
    })
}

export async function consoleSettingsInspect() {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/settingsInspect`,
        data: {}
    })
}

export async function consoleSettingsUpdate(options) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/settingsUpdate`,
        data: options
    })
}

export async function consoleSettingsInventoryUpdate() {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/inventoryUpdate`,
        data: {}
    })
}

export async function consoleSettingsCleanupBQ(locationId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/cleanupBQ`,
        // url:  `http://localhost:3001/console-v3/cleanupBQ`,
        data: {
            locationId
        }
    })
}


export async function consoleStationsInsightsInspect(endFrom, endTo, aggregationTimeZone, partnerId) {
    if (!moment.isMoment(endFrom)) {
        endFrom = moment(endFrom)
    }

    if (!moment.isMoment(endTo)) {
        endTo = moment(endTo)
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/stationsInsightsInspect`,
        data: {
            endFrom,
            endTo,
            aggregationTimeZone,
            partnerId: partnerId ? partnerId : null,
        }
    })
}

export async function consoleStationsSummaryInspect(endFrom, endTo, aggregationTimeZone, locationId, partnerId) {
    if (!moment.isMoment(endFrom)) {
        endFrom = moment(endFrom)
    }

    if (!moment.isMoment(endTo)) {
        endTo = moment(endTo)
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/stationsSummaryInspect`,
        data: {
            endFrom,
            endTo,
            aggregationTimeZone,
            locationId: locationId ? locationId : null,
            partnerId: partnerId ? partnerId: null
        }
    })
}

export async function consoleUserInspect(query, options) {
    if (!options) {
        options = {}
    }

    if (query.startsWith('+')) {
        options.phoneNumber = query
    } else {
        options.userId = query
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/userInspect`,
        data: options
    })
}

export async function consoleUserReservationsInspect(userId, startFrom, startTo, asc) {
    if (!moment.isMoment(startFrom)) {
        startFrom = moment(startFrom)
    }

    if (!moment.isMoment(startTo)) {
        startTo = moment(startTo)
    }

    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/userReservationsInspect`,
        data: {
            userId,
            startFrom,
            startTo,
            asc: !!asc
        }
    })
}

export async function consoleSetAllRelays(locationId, value) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v2/setAllRelays`,
        data: {
            locationId,
            value
        }
    })
}

export async function consoleSetLogSeverity(locationId, severity) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/console-v3/setLoggerSeverity`,
        data: {
            locationId,
            severity
        }
    })
}


async function request(options) {
    if (options.authenticated) {
        if (!options.headers) {
            options.headers = {}
        }
        options.headers.Authorization = `Bearer ${await getToken()}`
    }

    try {
        return (await axios(options)).data
    } catch (err) {
        if (err.response) {
            const resp = err.response

            if (resp.status === 401 || resp.status === 403) {
                throw new UnauthorizedError(err, options.errorMetadata)
            }

            const errorDetail = resp.data && resp.data.errorDetail ? resp.data.errorDetail : null
            throw new RequestError(resp.status, errorDetail, err, options.errorMetadata)
        }

        throw new UnexpectedError(err)
    }
}