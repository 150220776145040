import { Alert, Descriptions, Table, Typography } from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import update from 'react-addons-update'
import styled from 'styled-components'
import WeekSelector from '../../../../components/common/WeekSelector'
import { consoleSessionsInspect } from '../../../../logic/network'
import { formatDate } from '../../../../logic/utils'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

const StyledContainer = styled.div`
  text-align: center;
  padding: 0 24px 0 24px;
`

export default class StationReservations extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  static columns = [
    {
      title: 'Charging Session ID',
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>,
      width: 300
    },
    {
      title: 'Stall',
      dataIndex: 'stallNumber',
      key: 'stallNumber',
      render: v => v ? `${v}`.padStart(2, '0') : 'N/A',
      width: 50
    },
    {
      title: 'Partner',
      dataIndex: 'partnerId',
      key: 'partnerId',
      render: v => <StyledCode code>{v[0].toUpperCase()}{v.slice(1)}</StyledCode>,
    },
    {
      title: 'Start At',
      dataIndex: 'start',
      key: 'start',
      render: v => formatDate(v),
    },
    {
      title: 'End At',
      dataIndex: 'end',
      key: 'end',
      render: v => formatDate(v),
    },
    {
      title: 'Duration',
      dataIndex: 'formattedDuration',
      key: 'formattedDuration',
      width: 150
    },
    {
      title: 'Energy',
      dataIndex: 'formattedEnergy',
      key: 'formattedEnergy',
      width: 150
    }
  ]

  static renderExpandedRow(row) {
    return (
      <Descriptions bordered size='small' column={1}>
        <Descriptions.Item label='Start At'>{formatDate(row.start)}</Descriptions.Item>
        <Descriptions.Item label='End At'>{formatDate(row.end)}</Descriptions.Item>
        <Descriptions.Item label='Vehicle ID'>{row.vehicleId ? <StyledCode code>{row.vehicleId}</StyledCode> : 'N/A'}</Descriptions.Item>}
      </Descriptions>
    )
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      yearWeek: moment()
    }
  }

  async loadData(location, yearWeek) {
    const sessions = (await consoleSessionsInspect(yearWeek.clone().startOf('week'), yearWeek.clone().endOf('week'), location.id, null)).sessions

    const reservables = {}
    location.reservables.forEach((rsb, i) => reservables[rsb.id] = i+1)
    sessions.forEach(rsv => rsv.stallNumber = reservables[rsv.reservableId])

    return sessions
  }

  handleChange(yearWeek) {
    this.setState(update(this.state, {
      yearWeek: { $set: yearWeek }
    }))
  }

  render() {
    const { location } = this.props
    const { yearWeek } = this.state

    return (
      <Loads fn={this.loadData}  context='infrastructure/locations/location/station-reservations' variables={[location, yearWeek]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            {!isRejected &&
            <Fragment>
              <StyledContainer>
                <Alert message='This page shows all the charging sessions that ended in the given week.'/>
                <br/>
                <WeekSelector disabled={isPending} onChange={this.handleChange} value={yearWeek}/>
                <br/>
                <Table expandRowByClick={true} rowKey={r => r.id} expandedRowRender={StationReservations.renderExpandedRow} columns={StationReservations.columns} dataSource={!isPending && !isReloading && isResolved && response ? response : []} loading={isPending} pagination={false} size='middle' scroll={{y: 'calc(100vh - 23rem)'}}/>
              </StyledContainer>
            </Fragment>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}