import {Alert, Button, Form, Modal} from 'antd'
import PropTypes from 'prop-types'
import React, {Component, Fragment} from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import {consolePartnersAddPrivateLocations, consolePrivateLocations} from '../../../logic/network'
import {Loads} from "react-loads";
import LocalLoader from "../../../components/common/LocalLoader";
import { Select } from 'antd';

const { Option } = Select;

const StyledContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

export default class AddPrivateLocation extends Component {
  static propTypes = {
    onDone: PropTypes.func.isRequired,
    partnerId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmitAddPrivateLocation = this.handleSubmitAddPrivateLocation.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      loading: false,
      partnerId: this.props.partnerId,
      locations: null,
      locationIds: []
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmitAddPrivateLocation(e) {
    const {locationIds } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))

    try {
      await consolePartnersAddPrivateLocations(this.props.partnerId, locationIds)
      console.log(this.props.partnerId, locationIds)
      this.props.onDone(true)
    } catch (err) {
      const errorMessage = err.message
      this.setState(update(this.state, {
        errorMessage: { $set: errorMessage },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    console.log(field, value)
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      [field]: { $set: value }
    }))
  }

  async loadData() {
    return consolePrivateLocations()
  }

  render() {
    const { errorMessage, loading, locationIds} = this.state

    return (
      <Modal centered closable={true} footer={null} title='Add private location' visible={true} onCancel={this.handleCancel} transitionName='none'>
        <p>Add private location for partner: <b>{this.props.partnerId}</b></p>
        <StyledContainer>
          <StyledForm layout='inline' onSubmit={this.handleSubmitAddPrivateLocation}>
            <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
              <Loads fn={this.loadData}>
                {({ response, error, isPending, isResolved, isRejected }) => (
                    <Fragment>
                      {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                      {isResolved && <Select mode="multiple" allowClear onChange={(value) => this.handleChange('locationIds', value)} style={{ width: 470 }}>
                        {response.locations.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.address} - {item.id}({item.HALAdapterType})
                            </Option>
                        ))}
                      </Select> }
                      {isRejected && <Alert type='error' message={error.message}/>}
                    </Fragment>
                )}
              </Loads>
            </Form.Item>
            <br />
            <Form.Item>
              <Button disabled={(!locationIds.length)} htmlType='submit' loading={loading} type='primary'>Add</Button>
            </Form.Item>
          </StyledForm>
        </StyledContainer>
      </Modal>
    )
  }
}