import { Alert, Empty, Icon, Row, Col, Statistic } from 'antd'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import MomentPropTypes from 'react-moment-proptypes'
import styled from 'styled-components'
import LocalLoader from '../../../components/common/LocalLoader'
import DailySessionsChart from '../../../components/analytics/stations/station/summary/DailySessionsChart'
import DailyEnergyChart from '../../../components/analytics/stations/station/summary/DailyEnergyChart'
import DailyDurationChart from '../../../components/analytics/stations/station/summary/DailyDurationChart'
import { consoleStationsSummaryInspect } from '../../../logic/network'

const StyledRow = styled(Row)`
  padding: 48px 0 48px 0;
`

const StyledCol = styled(Col)`
  text-align: center;
`

export default class StationsSummary extends Component {
  static propTypes = {
    yearMonth: MomentPropTypes.momentObj.isRequired,
    partnerId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
  }

  async loadData(yearMonth, partnerId) {
    const summary = (await consoleStationsSummaryInspect(yearMonth.clone().startOf('month'), yearMonth.clone().endOf('month'), moment.tz.guess(), null, partnerId)).summary

    summary.daily = summary.daily.map(daily => {
      daily.day = daily.day.split('-')[2]
      return daily
    })

    return summary
  }

  render() {
    const { yearMonth, partnerId } = this.props

    return (
      <Loads fn={this.loadData}  context='analytics/stations/station/summary' variables={[yearMonth, partnerId]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved && response.totalSessions === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {isResolved && response.totalSessions > 0 &&
              <Fragment>
                <StyledRow gutter={16}>
                  <StyledCol span={8}>
                    <Statistic title='Charging Sessions' value={response.totalSessions} prefix={<Icon type='interaction'/>}/>
                  </StyledCol>
                  <StyledCol span={8}>
                    <Statistic
                      title='Delivered Energy' value={response.formattedTotalEnergy} prefix={<Icon type='thunderbolt'/>}/>
                  </StyledCol>
                  <StyledCol span={8}>
                    <Statistic
                      title='Cumulative Duration' value={response.formattedTotalDuration}
                      prefix={<Icon type='clock-circle'/>}/>
                  </StyledCol>
                </StyledRow>
                <DailySessionsChart data={response.daily} yearMonth={yearMonth}/>
                <DailyEnergyChart data={response.daily} yearMonth={yearMonth}/>
                <DailyDurationChart data={response.daily} yearMonth={yearMonth}/>
              </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}