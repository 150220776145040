import { Button, Form, Input, Modal } from 'antd'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import {completeSignIn, initiateSignIn} from '../logic/auth'
import { InvalidCodeError } from '../logic/errors'

const StyledFormContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

const StyledInput = styled(Input)`
  width: 20rem;
`

const StyledFooter = styled.p`
  margin-bottom: 0;
`

const StyledButton = styled(Button)`
  padding: 0;
`

export default class SignIn extends Component {
  constructor(props) {
    super(props)

    this.handleEmailPromptChange = this.handleEmailPromptChange.bind(this)
    this.handleEmailPromptSubmit = this.handleEmailPromptSubmit.bind(this)
    this.handleCodePromptChange = this.handleCodePromptChange.bind(this)
    this.handleCodePromptSubmit = this.handleCodePromptSubmit.bind(this)
    this.handleTryAgainClick = this.handleTryAgainClick.bind(this)

    this.state = {
      emailPrompt: {
        errorMessage: null,
        loading: false,
        value: ''
      },
      codePrompt: null,
      failedPrompt: null
    }
  }

  handleEmailPromptChange(e) {
    this.setState(update(this.state, {
      emailPrompt: {
        errorMessage: { $set: null },
        value: { $set: e.target.value }
      }
    }))
  }

  async handleEmailPromptSubmit(e) {
    const { emailPrompt } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      emailPrompt: {
        errorMessage: { $set: null },
        loading: { $set: true }
      }
    }))

    try {
      const challenge = await initiateSignIn(emailPrompt.value)

      this.setState(update(this.state, {
        emailPrompt: { $set: null },
        codePrompt: {
          $set: {
            challenge: challenge,
            errorMessage: null,
            loading: false,
            value: ''
          }
        }
      }))
    } catch (err) {
      this.setState(update(this.state, {
        emailPrompt: {
          errorMessage: { $set: err.message },
          loading: { $set: false }
        }
      }))
    }
  }

  handleCodePromptChange(e) {
    this.setState(update(this.state, {
      codePrompt: {
        errorMessage: { $set: null },
        value: { $set: e.target.value }
      }
    }))
  }

  async handleCodePromptSubmit(e) {
    const { codePrompt } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      codePrompt: {
        errorMessage: { $set: null },
        loading: { $set: true }
      }
    }))

    try {
      await completeSignIn(codePrompt.challenge, codePrompt.value)
    } catch (err) {
      if (err instanceof InvalidCodeError && err.final) {
        return this.setState(update(this.state, {
          codePrompt: { $set: null },
          failedPrompt: { $set: {} }
        }))
      }

      this.setState(update(this.state, {
        codePrompt: {
          errorMessage: { $set: err.message },
          loading: { $set: false }
        }
      }))
    }
  }

  handleTryAgainClick() {
    this.setState(update(this.state, {
      emailPrompt: {
        $set: {
          errorMessage: null,
          loading: false,
          value: ''
        }
      },
      failedPrompt: { $set: null }
    }))
  }

  render() {
    const { emailPrompt, codePrompt, failedPrompt } = this.state

    return (
      <Modal transitionName='none' centered closable={false} footer={null} title='Charge Console' visible={true}>
        {emailPrompt &&
        <Fragment>
          <p>Welcome to Charge. If you have a Console Account, please provide your authorized email address to sign in.</p>
          <StyledFormContainer>
            <StyledForm layout='inline' onSubmit={this.handleEmailPromptSubmit}>
              <Form.Item help={emailPrompt.errorMessage} validateStatus={emailPrompt.errorMessage ? 'error' : null}>
                <StyledInput disabled={emailPrompt.loading} onChange={this.handleEmailPromptChange} placeholder='email@domain.com' value={emailPrompt.value}/>
              </Form.Item>
              <Form.Item>
                <Button disabled={!emailPrompt.value.length} htmlType='submit' loading={emailPrompt.loading} type='primary'>Sign In</Button>
              </Form.Item>
            </StyledForm>
          </StyledFormContainer>
          <StyledFooter>Never heard of Charge? <a href='https://charge.us'>Learn more!</a></StyledFooter>
        </Fragment>
        }

        {codePrompt &&
        <Fragment>
          <p>Please provide the single use verification code received via email.</p>
          <StyledFormContainer>
            <StyledForm layout='inline' onSubmit={this.handleCodePromptSubmit}>
              <Form.Item help={codePrompt.errorMessage} validateStatus={codePrompt.errorMessage ? 'error' : null}>
                <StyledInput disabled={codePrompt.loading} onChange={this.handleCodePromptChange} placeholder='3ks9a...' value={codePrompt.value}/>
              </Form.Item>
              <Form.Item>
                <Button disabled={!codePrompt.value.length} htmlType='submit' loading={codePrompt.loading} type='primary'>Verify</Button>
              </Form.Item>
            </StyledForm>
          </StyledFormContainer>
        </Fragment>
        }

        {failedPrompt &&
        <Fragment>
          <p>
            Sorry, we are not able to authenticate you using this code. Either it is invalid, or it has expired.
            <StyledButton type='link' onClick={this.handleTryAgainClick}>Try again.</StyledButton>
          </p>
        </Fragment>
        }
      </Modal>
    )
  }
}