import {Alert, Button, Collapse, Form, Input, InputNumber, Select, Typography} from 'antd'
import React, {Component, useState} from 'react'
import styled from 'styled-components'
import { consoleLocationUpdate, consoleSetAllRelays, consoleSetLogSeverity } from '../../../../logic/network'
import CoordinatesPicker from "./CoordinatesPicker";
import update from "react-addons-update";
import DeleteLocation from "./DeleteLocation";
import {createValidationCode} from "../../../../logic/utils"

const { Option } = Select;

const StyledAlert = styled(Alert)`
  padding-right: 9rem !important;
`

const StyledCommands = styled.div`
  & > p:last-child {
    margin: 0;
  }
  
  & .ant-alert {
    margin-bottom: 14px;
  }
  
  & span.ant-typography {
    display: block;
    padding-bottom: 4px;
  }
  
  & .inline {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
  }
  
  & .inline > p {
    margin-right: 14px;
  }
`

const StyledButtons = styled.div`
  padding: 24px 0 24px 0;
  overflow-y: auto;
`

// export default function StationOperations({ location, onRefresh }) {
export default class StationOperations extends Component {
  constructor(props) {
    super(props)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleDone = this.handleDone.bind(this)

    this.state = {
      location: this.props.location,
      onRefresh: this.props.onRefresh,
      refreshCount: 0,
      isDeleteLocation: false,
    }
  }

  handleOpen(target, value) {
    this.setState(update(this.state, {
      [target]: { $set: value ? value : true }
    }))
  }

  handleDone(refresh) {
    console.log(this.state)
    this.setState(update(this.state, {
      refreshCount: { $apply: v => refresh ? v + 1 : v },
      isDeleteLocation: { $set: false }
    }))
  }

  render() {
    const { location, onRefresh, isDeleteLocation } = this.state
    return (
        <React.Fragment>
          <Collapse defaultActiveKey={['visibility', 'placement']}>
            <Collapse.Panel key='visibility' header='Visibility'>
              <Visibility location={location} onRefresh={onRefresh}/>
            </Collapse.Panel>
            <Collapse.Panel key='placement' header='Placement'>
              <Placement location={location} onRefresh={onRefresh}/>
            </Collapse.Panel>
            <Collapse.Panel key='config' header='Station configuration'>
              <SetLogSeverity location={location} onRefresh={onRefresh}/>
            </Collapse.Panel>
            {(location.operatingMode === 'private') ? <Collapse.Panel key='stalls-manipulations' header='Stalls Manipulations'>
              <StallsRelays location={location} onRefresh={onRefresh}/>
            </Collapse.Panel> : ""
            }
          </Collapse>
          {isDeleteLocation && <DeleteLocation locationId={location.id} validConfirmationCode={createValidationCode(5)} onDone={this.handleDone}/>}
          <StyledButtons>
            <Button type='danger' onClick={() => this.handleOpen('isDeleteLocation')}>Delete location</Button>
          </StyledButtons>
        </React.Fragment>
    )
  }
}

function Visibility({ location, onRefresh }) {
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(null)

  const handleClick = async () => {
    try {
      setLoading(true)
      await consoleLocationUpdate(location.id, {
        visibility: location.consoleVisibility === 'visible' ? 'hidden' : 'visible'
      })
      onRefresh()
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledCommands>
      {error !== null && <StyledAlert closeText='Clear' message={error} onClose={() => setError(null)} type='error'/>}
      <p>The station is currently {location.consoleVisibility === 'visible' ? 'VISIBLE' : 'INVISIBLE'} in maps and search functions.</p>
      <p><Button loading={loading} type='primary' onClick={handleClick}>{location.consoleVisibility === 'visible' ? 'Make Invisible' : 'Make Visible' }</Button></p>
    </StyledCommands>
  )
}

function StallsRelays({ location, onRefresh }) {
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(null)

  const handleClick = async (value) => {
    try {
      if (location.operatingMode !== 'private') {
        return setError('Change station operating mode to "private" and try again.')
      }
      setLoading(true)
      // Call API handler
      await consoleSetAllRelays(location.id, value)
      onRefresh()
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledCommands>
      {error !== null && <StyledAlert closeText='Clear' message={error} onClose={() => setError(null)} type='error'/>}
      <p><Button loading={loading} type='primary' onClick={() => handleClick(`on`)}>Set All Relays ON</Button></p>
      <p><Button loading={loading} type='primary' onClick={() => handleClick(`off`)}>Set All Relays OFF</Button></p>
    </StyledCommands>
  )
}

function SetLogSeverity({ location, onRefresh }) {
  const SEVERITY_LIST = ["info", "warn", "debug", "error"]
  const [ loading, setLoading ] = useState(false)
  const [ severity, setSeverity ] = useState(SEVERITY_LIST[0])
  const [ error, setError ] = useState(null)

  const handleClick = async () => {
    try {
      setLoading(true)
      await consoleSetLogSeverity(location.id, severity)
      onRefresh()
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
      <StyledCommands>
        {error !== null && <StyledAlert closeText='Clear' message={error} onClose={() => setError(null)} type='error'/>}
        <Form.Item label='Logger severity type' rules={[{ required: true }]}>
          <Select defaultValue={SEVERITY_LIST[0]} onChange={(value) => setSeverity(value)} style={{ width: '10rem' }}>
            { SEVERITY_LIST.map((el, i) =>
                <Option key={el} value={el}>{el}</Option>
            )}
          </Select>
        </Form.Item>
        <p><Button loading={loading} type='primary' onClick={() => handleClick()}>Set severity</Button></p>
      </StyledCommands>
  )
}

function Placement({ location, onRefresh }) {
  const [ address, setAddress ] = useState(location.address)
  const [ coordinates, setCoordinates ] = useState(location.coordinates)
  const [ isPickerOpen, setIsPickerOpen ] = useState(false)
  const [ isChanged, setIsChanged ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(null)

  const handlePick = (coordinates) => {
    setCoordinates(coordinates)
    setIsPickerOpen(false)
    setIsChanged(true)
  }

  const handleChange = (field, value) => {
    switch (field) {
      case 'address':
        setAddress(value)
        break
      case 'lat':
        setCoordinates({ ...coordinates, lat: value })
        break
      case 'lng':
        setCoordinates({ ...coordinates, lng: value })
        break
      default:
        break
    }

    setIsChanged(true)
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      await consoleLocationUpdate(location.id, {
        address,
        coordinates
      })
      onRefresh()
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const isUpdatePlacementEnabled = () => {
    if (!isChanged || !address) {
      return false
    }

    if (coordinates.lat < -90 || coordinates.lat > 90) {
      return false
    }

    return coordinates.lng >= -180 && coordinates.lng <= 180
  }

  return (
    <StyledCommands>
      {error !== null && <StyledAlert closeText='Clear' message={error} onClose={() => setError(null)} type='error'/>}
      <div className='inline'>
      <p>
        <Typography.Text>Address:</Typography.Text>
      </p>
      <p style={{flex: '1 0 auto'}}>
        <Input onChange={e => handleChange('address', e.target.value)} value={address}/>
      </p>
      </div>
      <div className='inline'>
        <p>
          <Typography.Text>Latitude:</Typography.Text>
        </p>
        <div style={{flex: '1 0 auto', margin: '0 14px 14px 0'}}>
          <InputNumber onChange={e => handleChange('lat', e)} style={{width: '100%'}} value={coordinates.lat}/>
        </div>
        <p>
          <Typography.Text>Longitude:</Typography.Text>
        </p>
        <div style={{flex: '1 0 auto', margin: '0 14px 14px 0'}}>
          <InputNumber onChange={e => handleChange('lng', e)} style={{width: '100%'}} value={coordinates.lng}/>
        </div>
        <p>
          <Button onClick={() => setIsPickerOpen(true)}>Pick Coordinates</Button>
          {isPickerOpen && <CoordinatesPicker coordinates={coordinates} onCancel={() => setIsPickerOpen(false)} onPick={handlePick}/>}
        </p>
      </div>
      <p>
        <Button disabled={!isUpdatePlacementEnabled()} loading={loading} type='primary' onClick={handleClick}>Update Placement</Button>
      </p>
    </StyledCommands>
  )
}
