import {Button, Form, Input, Modal, Alert} from 'antd'
import PropTypes from 'prop-types'
import React, {Component, Fragment} from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import { consoleDeleteLocation } from '../../../../logic/network'

const StyledContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

const StyledInput = styled(Input)`
  width: 20rem;
`


export default class DeleteLocation extends Component {
  static propTypes = {
    locationId: PropTypes.string.isRequired,
    validConfirmationCode: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      successMessage: null,
      loading: false,
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmit(e) {
    const { locationId } = this.props
    e.preventDefault()

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))

    try {
      const resp = await consoleDeleteLocation(locationId)
      this.setState(update(this.state, {
        successMessage: { $set: resp.info },
        loading: { $set: false }
      }))
      setTimeout(() => {
        // this.props.onDone(true)
        window.location = "/infrastructure/locations";
      }, 1500)
    } catch (err) {
      const errorMessage = err.message
      this.setState(update(this.state, {
        errorMessage: { $set: errorMessage },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      [field]: { $set: value }
    }))
  }

  render() {
    const {validConfirmationCode} = this.props
    const { errorMessage, successMessage, loading, confirmationCode } = this.state

    return (
      <Modal centered closable={true} footer={null} title='Delete location' visible={true} onCancel={this.handleCancel} transitionName='none'>
        <Fragment>
          {successMessage && <Alert message="Location deleted successfully" type='success' description={successMessage} showIcon/>}
          {errorMessage && <Alert message="Failed to delete location" type='error' description={errorMessage} showIcon/>}
        </Fragment>
        {!successMessage && <Alert message="Delete location from system without recovery!" type="warning" showIcon/> }
        <br/>
        <p>For confirmation please enter validation code: <b>{validConfirmationCode}</b></p>
        <StyledContainer>
          <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
            <StyledInput disabled={loading} onChange={(e) => this.handleChange('confirmationCode', e.target.value)} placeholder='Confirmation code' value={confirmationCode}/>
          </Form.Item>
          <StyledForm layout='inline' onSubmit={this.handleSubmit}>
            <Form.Item>
              <Button htmlType='button' onClick={this.handleCancel} type='primary'>Cancel</Button>
            </Form.Item>
            <Form.Item>
              <Button disabled={(confirmationCode !== validConfirmationCode)} htmlType='submit' loading={loading} type='danger'>Delete</Button>
            </Form.Item>
          </StyledForm>

        </StyledContainer>
      </Modal>
    )
  }
}