import { Alert, Tabs } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import ContainerReservations from './location/ContainerReservations'
import StationReservations from './location/StationReservations'
import LocationDiagnostics from './location/LocationDiagnostics'
import StationTest from './location/StationTest'
import LocalLoader from '../../../components/common/LocalLoader'
import ContainerOverview from '../../../components/infrastructure/locations/location/ContainerOverview'
import StationOverview from '../../../components/infrastructure/locations/location/StationOverview'
import { consoleLocationInspect } from '../../../logic/network'
import StationOperations from "./location/StationOperations";

const StyledTabsContainer = styled.div`
  & .ant-tabs-bar {
    padding-left: 24px !important;
  }
 
  & .ant-tabs-tab {
    margin-right: 8px !important;
  }
  
  & .ant-tabs-tabpane {
    padding-top: 0.5rem;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 12.5rem);
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

export default class Location extends Component {
  static propTypes = {
    locationId: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.loadLocation = this.loadLocation.bind(this)
    this.loadExtra = this.loadExtra.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)

    this.state = {
      refreshCounter: 0
    }
  }

  async loadLocation(locationId) {
    try {
      return await consoleLocationInspect(locationId, {
        includeReservableAvailabilities: true,
        includeLocationStatus: true,
        includeActiveReservations: true,
        includeAlerts: true
      })
    } catch (err) {
      if (err.status === 400 || err.status === 404) {
        throw new Error('Location not found.')
      }
      throw err
    }
  }

  async loadExtra(locationId) {
    try {
      return (await consoleLocationInspect(locationId, {
        includeSnapshot: true,
        includeBalenaDevice: true,
      }))
    } catch (err) {
      console.warn(err)
      return {}
    }
  }

  handleRefresh() {
    this.setState({
      refreshCounter: this.state.refreshCounter + 1
    })
  }

  render() {
    const { locationId } = this.props
    const { refreshCounter } = this.state

    return (
      <Loads fn={this.loadLocation}  context='infrastructure/locations/location' variables={[locationId, refreshCounter]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
              <Loads fn={this.loadExtra} context='infrastructure/locations/location/extra' variables={[locationId, refreshCounter, response]}>
                {({ response: extraResponse, isPending: extraIsPending, isResolved: extraIsResolved, isRejected: extraIsRejected }) => (
                  <Fragment>
                    <StyledTabsContainer>
                      <Tabs type='card'>
                        <Tabs.TabPane key='location' tab={(response.location.type === 'station' || response.location.type === 'private-station') ? 'Station' : 'Container'}>
                          <StyledContainer>
                            {(response.location.type === 'station' || response.location.type === 'private-station') &&
                              <StationOverview location={response.location} stationStatus={response.stationStatus} activeReservations={response.activeReservations} alerts={response.alerts} stationSnapshot={extraResponse ? extraResponse.stationSnapshot : null}/>
                            }
                            {response.location.type === 'container' &&
                              <ContainerOverview location={response.location}/>
                            }
                          </StyledContainer>
                        </Tabs.TabPane>
                        {(response.location.type === 'station' || response.location.type === 'private-station') &&
                          <Tabs.TabPane key='chargingSessions' tab='Charging Sessions'>
                            <StationReservations location={response.location}/>
                          </Tabs.TabPane>
                        }
                        {response.location.type === 'container' &&
                          <Tabs.TabPane key='reservations' tab='Bay Reservations'>
                            <ContainerReservations location={response.location}/>
                          </Tabs.TabPane>
                        }
                        <Tabs.TabPane key='diagnostics' tab='Diagnostics'>
                          <StyledContainer>
                            <LocationDiagnostics balenaDevice={extraResponse ? extraResponse.balenaDevice : null} location={response.location} stationStatus={response.stationStatus} activeReservations={response.activeReservations} stationSnapshot={extraResponse ? extraResponse.stationSnapshot : null}/>
                          </StyledContainer>
                        </Tabs.TabPane>
                        {(response.location.type === 'station' || response.location.type === 'private-station') &&
                          <Tabs.TabPane key='stationTest' tab='Station Test'>
                            <StyledContainer>
                              <StationTest location={response.location}/>
                            </StyledContainer>
                          </Tabs.TabPane>
                        }
                        {(response.location.type === 'station' || response.location.type === 'private-station') &&
                          <Tabs.TabPane key='stationOperations' tab='Operations'>
                            <StyledContainer>
                              <StationOperations location={response.location} onRefresh={this.handleRefresh}/>
                            </StyledContainer>
                          </Tabs.TabPane>
                        }
                      </Tabs>
                    </StyledTabsContainer>
                  </Fragment>
                )}
              </Loads>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}