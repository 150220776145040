import {Button, Form, Modal} from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import {consolePartnersEntityDelete} from '../../../logic/network'

const StyledContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

export default class DeletePartnerEntity extends Component {
  static propTypes = {
    partnerId: PropTypes.string.isRequired,
    partnerEntityName: PropTypes.string.isRequired,
    partnerEntityId: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      loading: false,
      partnerId: '',
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmit(e) {
    e.preventDefault()
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))


    try {
      await consolePartnersEntityDelete(this.props.partnerId, this.props.partnerEntityId)
      this.props.onDone(true)
    } catch (err) {
      let errorMessage = err.message
      if (err.detail !== null) {
        errorMessage = err.detail
      }

      this.setState(update(this.state, {
        errorMessage: { $set: errorMessage },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      [field]: { $set: value }
    }))
  }

  render() {
    const { errorMessage, loading } = this.state

    return (
      <Modal centered closable={true} footer={null} title='Delete partner entity' visible={true} onCancel={this.handleCancel} transitionName='none'>
        <StyledContainer>
          <p>Delete partner entity: "<b>{this.props.partnerEntityName}</b>"?</p>
          <StyledForm layout='inline' onSubmit={this.handleSubmit}>
            <Form.Item>
              <Button htmlType='button' onClick={this.handleCancel} type='primary'>Cancel</Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' loading={loading} type='danger'>Delete</Button>
            </Form.Item>
            <br />
            <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : null}/>
          </StyledForm>
        </StyledContainer>
      </Modal>
    )
  }
}