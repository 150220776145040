import moment from 'moment'
import config from './config'

export function formatDate(strOrMoment) {
  if (typeof strOrMoment === 'string') {
    strOrMoment = moment(strOrMoment)
  }
  return `${strOrMoment.format('L [at] LTS')}`
}


export function formatSince(strOrMoment) {
  if (typeof strOrMoment === 'string') {
    strOrMoment = moment(strOrMoment)
  }
  return strOrMoment.fromNow()
}

export function staticMapUrl(center, size) {
  return `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+36da96(${center.lng},${center.lat})/${center.lng},${center.lat},14,0,0/${size}@2x?logo=false&access_token=${config.mapboxAccessToken}`
}

export function googleMapsUrl(center) {
  return `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`
}

export function googleMapsAddressUrl(address) {
  return `https://www.google.com/maps/search/?api=1&query=${address}`
}

export function stripeCustomerIdUrl(customerId) {
  return `https://dashboard.stripe.com/${config.stage === 'prod' ? '' : 'test/'}customers/${customerId}`
}

export function stripePaymentIntentIdUrl(paymentIntentId) {
  return `https://dashboard.stripe.com/${config.stage === 'prod' ? '' : 'test/'}payments/${paymentIntentId}`
}

export function cognitoUserUrl(userId) {
  return `https://console.aws.amazon.com/cognito/users/?region=us-east-1#/pool/${config.cognitoJuicerAppUserPoolId}/users/${userId}`
}

export function formatCurrency(value, currency) {
  const factor = Math.pow(10, currency.decimals)
  const frac = `${value%factor}`.padStart(currency.decimals, '0')
  const int = `${Math.floor(value/factor)}`
  return `${int}.${frac} ${currency.code}`
}

export function isOnline(strOrMoment) {
  let isOnline = false
  if (typeof strOrMoment === 'string') {
    strOrMoment = moment(strOrMoment)
  }

  let now = moment(new Date());
  let duration = moment.duration(now.diff(strOrMoment));
  let minutes = duration.asMinutes();

  if (minutes <= 15 ) {
    isOnline = true
  }

  return isOnline
}

export function createValidationCode(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}