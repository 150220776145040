import { Button, DatePicker, Form, Icon } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class WeekSelector extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired
  }

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }

  handleChange(value) {
    this.props.onChange(value)
  }

  handlePrev() {
    this.handleChange(this.props.value.add(-1, 'w'))
  }

  handleNext() {
    this.handleChange(this.props.value.add(1, 'w'))
  }

  render() {
    const { disabled, value } = this.props

    return (
      <Form layout='inline'>
        <Form.Item>
          <Button.Group>
            <Button disabled={disabled} onClick={this.handlePrev} size='small' type='primary'><Icon type='left' /> Prev Week</Button>
            <Button disabled={true} size='small' type='primary'>{value.startOf('week').format('L')}</Button>
          </Button.Group>
        </Form.Item>
        <Form.Item><DatePicker.WeekPicker disabled={disabled} onChange={this.handleChange} value={value}/></Form.Item>
        <Form.Item>
          <Button.Group>
            <Button disabled={true} size='small' type='primary'>{value.endOf('week').format('L')}</Button>
            <Button disabled={disabled} onClick={this.handleNext} size='small' type='primary'>Next Week <Icon type='right' /></Button>
          </Button.Group>
        </Form.Item>
      </Form>
    )
  }
}