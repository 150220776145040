import { Map, Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class LocationsMap extends Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    onMove: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.markers = {}
  }

  componentDidMount() {
    this.map = new Map({
      container: this.container,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-25.263746139378554, 43.503959523712325],
      zoom: 1.5
    })

    if (this.props.onMove) {
      this.map.on('move', () => this.props.onMove(this.map.getBounds()))
      this.props.onMove(this.map.getBounds())
    }
  }

  componentDidUpdate() {
    this.props.locations.forEach(l => {
      if (!this.markers[l.id]) {
        let markerColor = ''
        switch(l.type) {
          case 'container':
            markerColor = '#36DA96'
            break;
          case 'station':
            markerColor = '#1890FF'
            break;
          case 'private-station':
            markerColor = '#FFBE18'
            break;
          default:
            markerColor = '#1890FF'
        }

        const m = new Marker({color: markerColor}).setLngLat([l.coordinates.lng, l.coordinates.lat]).addTo(this.map)
        m.location = l

        if (this.props.onClick) {
          m.getElement().addEventListener('click', () => this.props.onClick(l.id))
        }

        m.getElement().style.cursor = 'pointer'
        this.markers[l.id] = m
      } else {
        this.markers[l.id].location = l
      }
    })
  }

  componentWillUnmount() {
    this.map.remove()
  }

  render() {
    return (
      <div ref={el => this.container = el} style={{width: '100%', height: 'calc(100vh - 12.5rem)'}}/>
    )
  }
}