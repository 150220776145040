import { Tag } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'


const StyledSeverity = styled(Tag)`
  text-align: center; 
  width: 4rem;
`

export default class Severity extends Component {
  static propTypes = {
    severity: PropTypes.oneOf(['low', 'medium', 'high']).isRequired
  }

  render() {
    switch (this.props.severity) {
      case 'low': return <StyledSeverity color='gold'>low</StyledSeverity>
      case 'medium': return <StyledSeverity color='orange'>medium</StyledSeverity>
      case 'high': return <StyledSeverity color='red'>high</StyledSeverity>
      default: return <Fragment/>
    }
  }
}