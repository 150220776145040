import {Alert, Button, Card, Icon, Typography} from 'antd'
import countryCodeEmoji from 'country-code-emoji'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../components/common/LocalLoader'
import { consolePartnersInspect } from '../../logic/network'
import AddPartner from './partners/AddPartner'
import update from "react-addons-update";

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledCards = styled.div`
  margin-bottom: -16px;
  margin-right: -16px;

  & .ant-card {
    width: 250px;
    float: left;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  &::after {
    content: "";
    clear: both;
    display: table;
  }
  
  & .ant-card-actions li {
    margin: 6px 0 4px 0;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 12.5rem);
`

const StyledButtons = styled.div`
  padding: 0 24px 24px 24px;
  overflow-y: auto;
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

export default class Partners extends Component {
  static columns = [
    {
      title: 'Partner ID',
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>,
      width: 300
    },
    {
      title: 'Partner Name',
      dataIndex: 'partnerName',
      key: 'name'
    }
  ]

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleDone = this.handleDone.bind(this)

    this.state = {
      refreshCount: 0,
      isAddPartner: false,
    }
  }

  async loadData() {
    return (await consolePartnersInspect()).partners
  }

  handleOpen(target, value) {
    this.setState(update(this.state, {
      [target]: { $set: value ? value : true }
    }))
  }

  handleDone(refresh) {
    console.log(this.state)
    this.setState(update(this.state, {
      refreshCount: { $apply: v => refresh ? v + 1 : v },
      isAddPartner: { $set: false }
    }))
  }

  render() {
    const { navigate } = this.props
    const { refreshCount, isAddPartner } = this.state
    return (
      <Loads fn={this.loadData}  context='infrastructure/partners' variables={[refreshCount]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            <StyledButtons>
              <Button type='primary' onClick={() => this.handleOpen('isAddPartner')}>Add Partner</Button>
            </StyledButtons>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
              <StyledContainer>
                <StyledCards>
                  {response.map(p =>
                    <Card actions={[<Icon key='ellipsis' type='ellipsis' onClick={() => navigate(`/infrastructure/partners/${p.id}`)} />]} key={p.id} extra={<StyledCode code>{p.id}</StyledCode>} size='small' title={p.name}>
                      {(!p.entities) && <div><i>No legal entities</i></div>}
                      {(p.entities != null) && p.entities.map(e =>
                        <div key={e.id}>{e.name} {countryCodeEmoji(e.countryCode)}</div>
                      )}
                    </Card>
                  )}
                </StyledCards>
              </StyledContainer>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
            {isAddPartner && <AddPartner onDone={this.handleDone}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}