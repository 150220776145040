import { Collapse } from 'antd'
import JSONTree from 'react-json-tree'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { base16theme } from '../../../../logic/config'

const StyledCollapsePanel = styled(Collapse.Panel)`
  & .ant-collapse-content-box {
    padding: 8px 16px;
  }
  
  & ul {
    border: 0 !important;
    margin: 0 !important;
  }
`

export default class LocationDiagnostics extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    stationStatus: PropTypes.object,
    activeReservations: PropTypes.array,
    stationSnapshot: PropTypes.object,
    balenaDevice: PropTypes.object
  }

  render() {
    const { location, stationStatus, activeReservations, stationSnapshot, balenaDevice } = this.props

    return (
      <Fragment>
        <Collapse defaultExp defaultActiveKey={['location', 'stationStatus', 'activeReservations', 'stationSnapshot', 'balenaDevice']}>
          <StyledCollapsePanel key='location' header='Location'>
            <JSONTree data={location} hideRoot invertTheme={false} theme={base16theme}/>
          </StyledCollapsePanel>
          {stationStatus &&
            <StyledCollapsePanel key='stationStatus' header='Station Status'>
              <JSONTree data={stationStatus} hideRoot invertTheme={false} theme={base16theme}/>
            </StyledCollapsePanel>
          }
          {activeReservations &&
            <StyledCollapsePanel key='activeReservations' header='Active Reservations'>
              <JSONTree data={activeReservations} hideRoot invertTheme={false} theme={base16theme}/>
            </StyledCollapsePanel>
          }
          {stationSnapshot && stationSnapshot.leds &&
            <StyledCollapsePanel key='stationSnapshot' header='Station Snapshot'>
              <JSONTree data={stationSnapshot} hideRoot invertTheme={false} theme={base16theme}/>
            </StyledCollapsePanel>
          }
          {balenaDevice &&
            <StyledCollapsePanel key='balenaDevice' header='Balena Device'>
              <JSONTree data={balenaDevice} hideRoot invertTheme={false} theme={base16theme}/>
            </StyledCollapsePanel>
          }
        </Collapse>

      </Fragment>
    )
  }
}

// brewer