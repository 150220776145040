import {Table, Typography} from 'antd'
import PropTypes from 'prop-types'
import React,  { Component } from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  padding: 24px 0 24px 0;
  
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

export default class LocationsTable extends Component {
  static propTypes = {
    locations: PropTypes.array.isRequired
  }

  static columns = [
    {
      title: 'Location ID',
      dataIndex: 'locationId',
      key: 'locationId',
      render: v => <StyledCode code>{v}</StyledCode>,
      width: 200
    },
    {
      title: 'Street address of the location.',
      dataIndex: 'locationAddress',
      key: 'locationAddress',
    },
    {
      title: 'Charging Sessions',
      dataIndex: 'totalSessions',
      key: 'totalSessions',
      width: 150
    },
  ]

  render() {
    return (
      <StyledTable rowKey={r => r.locationId} columns={LocationsTable.columns} dataSource={this.props.locations} pagination={false} size='middle'/>    )
  }
}