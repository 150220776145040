import {Col, Descriptions, Row, Table, Tag, Typography} from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import SectionHeader from '../../../common/SectionHeader'
import {formatDate, formatSince, googleMapsUrl, staticMapUrl} from '../../../../logic/utils'
import Severity from "../../../common/Severity";

const StyledMap = styled.img`
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
  height: 13.5rem;
  object-fit: cover;
  width: 100%;
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledTag = styled(Tag)`
  text-align: center;
  width: 7rem;
`

const StyledDescriptions = styled(Descriptions)`
  & th {
    width: 8rem;
  }
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

export default class StationOverview extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    stationStatus: PropTypes.object,
    activeReservations: PropTypes.array,
    alerts: PropTypes.array,
    stationSnapshot: PropTypes.object
  }

  static ledToColor(led) {
    if (led.R === 1 && led.G === 1 && led.B === 1) {
      return 'blue' // "simulate" white
    }

    const [ r, g, b ] = [ Math.floor(led.R*255.0+0.5) % 256, Math.floor(led.G*255.0+0.5) % 256,  Math.floor(led.B*255.0+0.5) % 256 ]
    return `rgb(${r}, ${g}, ${b}`
  }

  alertColumns = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      render: v => <Severity severity={v}/>,
      width: 120
    },
    {
      title: 'Type',
      dataIndex: '',
      key: 'type',
      render: (v, r) => {
        if (v.locationBatteryAlert) return `Low Battery (${v.locationBatteryAlert.batteryStatus}%)`
        else return 'N/A'
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: v => formatDate(v),
      width: 250
    }
  ]

  render() {
    const { location, stationStatus, activeReservations, alerts, stationSnapshot } = this.props

    return (
      <Fragment>
        <SectionHeader>Overview</SectionHeader>
        <Row gutter={16}>
          <Col span={14}>
            <StyledDescriptions bordered column={1}>
              <Descriptions.Item label='Station ID'><StyledCode code>{location.id}</StyledCode></Descriptions.Item>
              <Descriptions.Item label='Locale'> {location.countryCode} · {location.timeZone}{location.currency ? ` · ${location.currency.code}` : ''}</Descriptions.Item>
              <Descriptions.Item label='Address'><a href={googleMapsUrl(location.coordinates)} target='_blank' rel='noopener noreferrer'>{location.address}</a></Descriptions.Item>
              <Descriptions.Item label='Station Type'>{location.HALAdapterType ? <span>{location.HALAdapterType}</span> : 'Unknown'}</Descriptions.Item>
              <Descriptions.Item label='Operating Mode'>{location.operatingMode}</Descriptions.Item>
              <Descriptions.Item label='Battery Status'>{stationStatus ? <span>{stationStatus.batteryStatus}% &middot; <i>updated {formatSince(stationStatus.updatedAt)}</i></span> : 'Unknown'}</Descriptions.Item>
            </StyledDescriptions>
          </Col>
          <Col span={10}>
            <a href={googleMapsUrl(location.coordinates)} target='_blank' rel='noopener noreferrer'><StyledMap src={staticMapUrl(location.coordinates, '500x700')}/></a>
          </Col>
        </Row>
        <br/>
        {alerts && alerts.length > 0 &&
          <Fragment>
            <SectionHeader>Open Alerts</SectionHeader>
            <StyledTable rowKey={r => r.id} columns={this.alertColumns} dataSource={alerts} pagination={false} size='middle'/>
            <br/>
          </Fragment>
        }

        <SectionHeader>Charging Stalls</SectionHeader>
        <StyledDescriptions bordered column={2}>
          { (location.operatingMode === 'private') ? (
              location.consoleReservables.map((rsb, i) =>
                <Descriptions.Item key={rsb.id} label={'Stall ' + `${i+1}`.padStart(2, '0')}>
                  <Fragment>
                    {stationSnapshot && stationSnapshot.powerMeters &&
                     stationSnapshot.powerMeters[i] && !stationSnapshot.powerMeters[i].scooterPluggedIn &&
                     stationSnapshot.powerMeters[i].current === 0 &&
                      <StyledTag color='blue'>Available</StyledTag>
                    }
                    {stationSnapshot && stationSnapshot.powerMeters &&
                    stationSnapshot.powerMeters[i] && !stationSnapshot.powerMeters[i].scooterPluggedIn &&
                    stationSnapshot.powerMeters[i].current > 0 &&
                    <StyledTag>Scooter charged</StyledTag>
                    }
                    {stationSnapshot && stationSnapshot.powerMeters &&
                     stationSnapshot.powerMeters[i] && !stationSnapshot.powerMeters[i].scooterPluggedIn &&
                     stationSnapshot.powerMeters[i].current > 0 &&
                    <StyledTag color='orange'>
                      Unplug scooter
                    </StyledTag>
                    }
                    {stationSnapshot && stationSnapshot.powerMeters &&
                     stationSnapshot.powerMeters[i] && stationSnapshot.powerMeters[i].scooterPluggedIn &&
                     stationSnapshot.powerMeters[i].current > 0 &&
                      <StyledTag color={stationSnapshot && stationSnapshot.leds && stationSnapshot.leds[i] ? StationOverview.ledToColor(stationSnapshot.leds[i]) : 'orange'}>
                        {stationSnapshot.powerMeters[i].scooterPluggedIn && `Charging ~ ${stationSnapshot.powerMeters[i].estimatedScooterBatteryStatus}%`}
                      </StyledTag>
                    }
                  </Fragment>
                </Descriptions.Item>
              )
          ) : (
              location.consoleReservables.map((rsb, i) =>
                    <Descriptions.Item key={rsb.id} label={'Stall ' + `${i+1}`.padStart(2, '0')}>
                      {(!activeReservations || activeReservations.filter(rsv => !rsv.consoleExpired && rsv.reservableId === rsb.id).length === 0) &&
                      <Fragment>{rsb.currentAvailability === 'available' ? <StyledTag color='blue'>Available</StyledTag> : <StyledTag color='red'>Busy</StyledTag>}</Fragment>
                      }
                      {activeReservations && activeReservations.filter(rsv => !rsv.consoleExpired && rsv.reservableId === rsb.id).map(rsv =>
                          <Fragment key={rsv.id}>
                            {rsb.currentAvailability === 'available' && <StyledTag color='blue'>Available</StyledTag>}
                            {rsb.currentAvailability === 'unavailable' && rsv.consoleProvisionalAt && <StyledTag>Waiting...</StyledTag>}
                            {rsb.currentAvailability === 'unavailable' && !rsv.consoleProvisionalAt &&
                            <StyledTag color={stationSnapshot && stationSnapshot.leds && stationSnapshot.leds[i] ? StationOverview.ledToColor(stationSnapshot.leds[i]) : 'orange'}>
                              Charging
                              {stationSnapshot && stationSnapshot.powerMeters && stationSnapshot.powerMeters[i] && stationSnapshot.powerMeters[i].scooterPluggedIn && ` ~ ${stationSnapshot.powerMeters[i].estimatedScooterBatteryStatus}%`}
                            </StyledTag>
                            }
                            <Tag>
                              {rsv.consolePartnerId && `${rsv.consolePartnerId[0].toUpperCase()}${rsv.consolePartnerId.slice(1)} `}
                              {moment.duration(moment().diff(rsv.unboundedStart ? rsv.unboundedStart : rsv.range.start)).format('[~] h[h] m[m]')}
                            </Tag>
                          </Fragment>
                      )}
                    </Descriptions.Item>
              )
          )}
        </StyledDescriptions>
      </Fragment>
    )
  }
}