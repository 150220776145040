import { Button, Form, Icon, Input, message } from 'antd'
import PropTypes from 'prop-types'
import React,  { Component } from 'react'
import update from 'react-addons-update'

export default class TextLookup extends Component {
  static propTypes = {
    action: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    width: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      loading: false,
      value: ''
    }
  }

  async handleSubmit(e) {
    e.preventDefault()

    this.setState(update(this.state, {
      loading: { $set: true },
    }))

    try {
      await this.props.onSubmit(this.state.value.trim())
    } catch (err) {
      message.error(err.message)

      return this.setState(update(this.state, {
        loading: { $set: false },
        value: { $set: '' }
      }))
    }

    this.setState(update(this.state, {
      loading: { $set: false },
      value: { $set: '' }
    }))
  }

  handleChange(e) {
    this.setState(update(this.state, {
      value: { $set: e.target.value }
    }))
  }

  render() {
    const { action, icon, placeholder, width } = this.props
    const { loading, value } = this.state

    return (
      <Form layout='inline' onSubmit={this.handleSubmit}>
        <Form.Item>
          <Input disabled={loading} onChange={this.handleChange} placeholder={placeholder} prefix={icon ? <Icon type={icon} style={{color: 'rgba(0,0,0,.25)'}} /> : null} style={{width}} value={value}/>
        </Form.Item>
        <Form.Item>
          <Button disabled={!value.length} htmlType='submit' loading={loading} type='primary'>
            {action}
          </Button>
        </Form.Item>
      </Form>
    )
  }
}