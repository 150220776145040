import { Checkbox, Descriptions, Table, Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import SectionHeader from '../../../common/SectionHeader'
import { cognitoUserUrl, stripeCustomerIdUrl, formatDate } from '../../../../logic/utils'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledDescriptions = styled(Descriptions)`
  & th {
    width: 8rem;
  }
`

export default class JuicerAppUserOverview extends Component {
  static propTypes = {
    cognitoUser: PropTypes.object.isRequired,
    stripeCustomer: PropTypes.object,
    stripePaymentMethods: PropTypes.array
  }

  static paymentMethodsColumns = [
    {
      title: 'Payment Method ID',
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>
    },
    {
      title: 'Label',
      dataIndex: 'displayLabel',
      key: 'label'
    },
    {
      title: 'Is Default',
      dataIndex: 'isDefault',
      key: 'default',
      render: v => <Checkbox defaultChecked={v} disabled/>
    }
  ]

  static getAttribute(cognitoUser, attribute) {
    const attributes = cognitoUser.Attributes.filter(a => a.Name === attribute)
    return attributes.length > 0 ? attributes[0].Value : null
  }

  render() {
    const { cognitoUser, stripeCustomer, stripePaymentMethods } = this.props

    const name = JuicerAppUserOverview.getAttribute(cognitoUser, 'name')
    const phoneNumber = JuicerAppUserOverview.getAttribute(cognitoUser, 'phone_number')
    const email = JuicerAppUserOverview.getAttribute(cognitoUser, 'email')

    return (
      <Fragment>
        <SectionHeader>Overview</SectionHeader>
        <StyledDescriptions bordered column={2}>
          <Descriptions.Item label='User ID'><a href={cognitoUserUrl(cognitoUser.Username)} target='_blank' rel='noopener noreferrer'><StyledCode code>{cognitoUser.Username}</StyledCode></a></Descriptions.Item>
          <Descriptions.Item label='Status'>{cognitoUser.UserStatus} / {cognitoUser.Enabled ? 'Enabled' : 'Disabled'}</Descriptions.Item>
          {name && <Descriptions.Item label='Name' span={2}>{name}</Descriptions.Item>}
          {phoneNumber && <Descriptions.Item label='Phone Number' span={2}><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></Descriptions.Item>}
          {email && <Descriptions.Item label='Email Address' span={2}><a href={`mailto:${email}`}>{email}</a></Descriptions.Item>}
          <Descriptions.Item label='Created At'>{formatDate(cognitoUser.UserCreateDate)}</Descriptions.Item>
          <Descriptions.Item label='Last Modified At'>{formatDate(cognitoUser.UserLastModifiedDate)}</Descriptions.Item>
        </StyledDescriptions>
        {stripeCustomer &&
        <Fragment>
          <br/>
          <SectionHeader>Stripe Details</SectionHeader>
          <StyledDescriptions bordered column={1}>
            <Descriptions.Item label='Customer ID'><a href={stripeCustomerIdUrl(stripeCustomer.id)} target='_blank' rel='noopener noreferrer'><StyledCode code>{stripeCustomer.id}</StyledCode></a></Descriptions.Item>
          </StyledDescriptions>
          {stripePaymentMethods &&
            <Fragment>
              <br/>
              <Table rowKey={r => r.id} columns={JuicerAppUserOverview.paymentMethodsColumns} dataSource={stripePaymentMethods} pagination={false} size='middle'/>
            </Fragment>
          }
        </Fragment>
        }
      </Fragment>
    )
  }
}