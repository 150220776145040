import { Alert, Col, Empty, Icon, Row, Statistic } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import MomentPropTypes from 'react-moment-proptypes'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../../components/common/LocalLoader'
import SectionHeader from '../../../components/common/SectionHeader'
import DropoffsDistributionChart from '../../../components/analytics/stations/station/insights/DropoffsDistributionChart'
import LocationsHeatMap from '../../../components/analytics/stations/station/insights/LocationsHeatMap'
import LocationsTable from '../../../components/analytics/stations/station/insights/LocationsTable'
import { consoleStationsInsightsInspect } from '../../../logic/network'

const StyledRow = styled(Row)`
  padding: 24px 0 48px 0;
`

const StyledCol = styled(Col)`
  text-align: center;
`

export default class Insights extends Component {
  static propTypes = {
    yearMonth: MomentPropTypes.momentObj.isRequired,
    partnerId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
  }

  async loadData(yearMonth, partnerId) {
    const insights = (await consoleStationsInsightsInspect(yearMonth.clone().startOf('month'), yearMonth.clone().endOf('month'), moment.tz.guess(), partnerId)).insights

    insights.dropoffsDistribution.map(d => {
      d.hour = `${d.hour}`
      return d
    })

    return insights
  }

  render() {
    const { yearMonth, partnerId } = this.props

    return (
      <Loads fn={this.loadData}  context='insights' variables={[yearMonth, partnerId]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            {(isPending || (!response && !isRejected)) && <LocalLoader/>}
            {isResolved && (!response.locationsDistribution || response.locationsDistribution.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {isResolved && response.locationsDistribution.length > 0 &&
            <Fragment>
              <SectionHeader>Charging Session Statistics</SectionHeader>
              <StyledRow gutter={16}>
                <StyledCol span={12}>
                  <Statistic title='Avg. Delivered Energy per Session' value={response.formattedAverageEnergy} prefix={<Icon type='interaction'/>}/>
                </StyledCol>
                <StyledCol span={12}>
                  <Statistic title='Avg. Charging Session Duration' value={response.formattedAverageDuration} prefix={<Icon type='thunderbolt'/>}/>
                </StyledCol>
              </StyledRow>
              <SectionHeader>Charging Sessions Started by Time of Day</SectionHeader>
              <DropoffsDistributionChart data={response.dropoffsDistribution}/>
              <SectionHeader>Charging Sessions by Location</SectionHeader>
              <LocationsHeatMap geoJson={response.locationsHeatMap}/>
              <LocationsTable locations={response.locationsDistribution}/>
            </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}