import {Button, Form, Input, Modal} from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import { consolePartnersCreate } from '../../../logic/network'

const StyledContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

const StyledInput = styled(Input)`
  width: 20rem;
`


export default class AddPartner extends Component {
  static propTypes = {
    onDone: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      loading: false,
      partnerId: '',
      partnerName: '',
      initialDashboardUserEmail: ''
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmit(e) {
    const { partnerId, partnerName, initialDashboardUserEmail } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))

    try {
      const resp = await consolePartnersCreate(partnerId, partnerName, initialDashboardUserEmail)
      console.log(partnerId, partnerName, initialDashboardUserEmail)
      console.log("resp=", resp)
      this.props.onDone(true)
    } catch (err) {
      // const errorMessage = (err instanceof RequestError && err.status === 400) ? 'Please provide a valid e-mail address.' : err.detail
      const errorMessage = err.message
      this.setState(update(this.state, {
        errorMessage: { $set: errorMessage },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      [field]: { $set: value }
    }))
  }

  render() {
    const { errorMessage, loading, partnerId, partnerName, initialDashboardUserEmail } = this.state

    return (
      <Modal centered closable={true} footer={null} title='Create new partner' visible={true} onCancel={this.handleCancel} transitionName='none'>
        <p>Partner can't be removed from system!</p>
        <StyledContainer>
          <StyledForm layout='inline' onSubmit={this.handleSubmit}>
            <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
              <StyledInput disabled={loading} onChange={(e) => this.handleChange('partnerId', e.target.value)} placeholder='Id' value={partnerId}/>
              <StyledInput disabled={loading} onChange={(e) => this.handleChange('partnerName', e.target.value)} placeholder='Name' value={partnerName}/>
              <StyledInput disabled={loading} onChange={(e) => this.handleChange('initialDashboardUserEmail', e.target.value)} placeholder='Initial dashboard user email' value={initialDashboardUserEmail}/>
            </Form.Item>
            <Form.Item>
              <Button disabled={(!partnerId.length) || (!partnerName.length) || (!initialDashboardUserEmail.length)} htmlType='submit' loading={loading} type='primary'>Add</Button>
            </Form.Item>
          </StyledForm>
        </StyledContainer>
      </Modal>
    )
  }
}