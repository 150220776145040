import { Spin } from 'antd'
import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.main`
  background-color: #f0f2f5;
  display: table-cell;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  width: 100vw;
`

export default class GlobalLoader extends Component {
    render() {
        return (
            <Container>
                <Spin size='large'/>
            </Container>
        )
    }
}