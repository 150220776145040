
export class InvalidCodeError extends Error {
    constructor(final, cause) {
        super('Please provide a valid code.')

        this.name = 'InvalidCodeError'
        this.final = final
        this.cause = cause

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidEmailError)
        }

        console.error(cause)
    }
}

export class InvalidEmailError extends Error {
    constructor(email, cause) {
        super('Please provide a valid email address.')

        this.name = 'InvalidEmailError'
        this.email = email
        this.cause = cause

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidEmailError)
        }

        console.error(cause)
    }
}

export class RequestError extends Error {
    constructor(status, detail, cause, metadata) {
        super('Ooops, something went wrong! Please try to refresh the page.')

        this.name = 'RequestError'
        this.status = status
        this.detail = detail
        this.cause = cause
        this.metadata = metadata

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidEmailError)
        }

        console.error(cause)
    }
}

export class UnauthorizedError extends Error {
    constructor(cause, metadata) {
        super('You are not authorized to see this page.')

        this.name = 'UnauthorizedError'
        this.cause = cause
        this.metadata = metadata

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnexpectedError)
        }

        console.error(cause)
    }
}

export class UnexpectedError extends Error {
    constructor(cause) {
        super('Ooops, something went wrong! Please try to refresh the page.')

        this.name = 'UnexpectedError'
        this.cause = cause

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnexpectedError)
        }

        console.error(cause)
    }
}