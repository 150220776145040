import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import mapboxGl from 'mapbox-gl'
import Amplify from 'aws-amplify'
import React from 'react'

const config = {
    stage: process.env.REACT_APP_STAGE,
    internalApiBaseUrl: process.env.REACT_APP_INTERNAL_API_BASE_URL,
    cognitoJuicerAppUserPoolId: process.env.REACT_APP_COGNITO_JUICER_APP_USER_POOL_ID,
    cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    cognitoUserPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY
}

export default config

export const bugsnagClient = bugsnag(config.bugsnagApiKey, {
    appType: 'partner-dashboard',
    notifyReleaseStages: ['staging', 'prod'],
    releaseStage: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : config.stage
})

bugsnagClient.use(bugsnagReact, React)
mapboxGl.accessToken = config.mapboxAccessToken

Amplify.configure({
    Analytics: {
        disabled: true
    },
    Auth: {
        region: 'us-east-1',
        userPoolId: config.cognitoUserPoolId,
        userPoolWebClientId: config.cognitoUserPoolClientId,
    }
})

export const base16theme = {
    scheme: 'charge',
    base00: '#ffffff',
    base01: '#ffffff',
    base02: '#ffffff',
    base03: '#737475',
    base04: '#959697',
    base05: '#b7b8b9',
    base06: '#dadbdc',
    base07: '#fcfdfe',
    base08: '#e31a1c',
    base09: '#e6550d',
    base0A: '#dca060',
    base0B: '#31a354',
    base0C: '#80b1d3',
    base0D: '#3182bd',
    base0E: '#756bb1',
    base0F: '#b15928'
}