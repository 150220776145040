import {Button, Card, Checkbox, Descriptions, Table, Typography} from 'antd'
import countryCodeEmoji from 'country-code-emoji'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import nl2br from 'react-nl2br'
import styled from 'styled-components'
import SectionHeader from '../../../common/SectionHeader'
import AddPartnerEntity from "../../../../containers/infrastructure/partners/AddPartnerEntity";
import update from "react-addons-update";
import DeletePartner from "../../../../containers/infrastructure/partners/DeletePartner";
import DeletePartnerEntity from "../../../../containers/infrastructure/partners/DeletePartnerEntity";

const StyledDescriptions = styled(Descriptions)`
  & th {
    width: 8rem;
  }
`

const StyledCards = styled.div`
  margin-bottom: -16px;
  margin-right: -16px;

  & .ant-card {
    width: 250px;
    float: left;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  &::after {
    content: "";
    clear: both;
    display: table;
  }
  
  & .ant-card-actions li {
    margin: 6px 0 4px 0;
  }
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledButtons = styled.div`
  padding: 0 24px 24px 0px;
  overflow-y: auto;
`

export default class PartnerOverview extends Component {
  static propTypes = {
    partner: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleDone = this.handleDone.bind(this)

    this.state = {
        isAddPartnerEntity: false,
        isDeletePartner: false,
        isDeletePartnerEntity: 0,
    }
  }

  static dashboardUsersColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    }
  ]

  static notificationEmailsColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Invoices',
      dataIndex: 'invoices',
      key: 'invoices',
      render: (v) => <Checkbox checked={v} disabled={true}/>,
      width: 200
    }
  ]

  handleOpen(target, value) {
    this.setState(update(this.state, {
        [target]: { $set: value ? value : true }
    }))
  }

  handleDone(refresh) {
    this.setState(update(this.state, {
        isAddPartnerEntity: { $set: false },
        isDeletePartner: { $set: false },
        isDeletePartnerEntity: { $set: 0 }
    }))
    if (refresh) {
        window.location.reload(refresh);
    }
  }


  render() {
    const { partner } = this.props
    const { isAddPartnerEntity, isDeletePartner, isDeletePartnerEntity} = this.state
    return (
      <Fragment>
        <SectionHeader>Overview</SectionHeader>
        <StyledDescriptions bordered column={1}>
          <Descriptions.Item label='Partner ID'><StyledCode code>{partner.id}</StyledCode></Descriptions.Item>
          <Descriptions.Item label='Name'>{partner.name}</Descriptions.Item>
        </StyledDescriptions>
        <br/>
        <SectionHeader>Partner Entities</SectionHeader>
        <StyledButtons>
          <Button type='primary' onClick={() => this.handleOpen('isAddPartnerEntity')}>Add Partner Entity</Button>
        </StyledButtons>
        <StyledCards>
          {(!partner.entities) && <Card><div><i>No legal entities</i></div></Card>}
          {(partner.entities) && partner.entities.map(e =>
            <Card extra={countryCodeEmoji(e.countryCode)} key={e.id} size='small' title={e.name}>
              <p><i>Tax Id</i><br/>{e.taxId}</p>
              <p><i>Address</i><br/>{nl2br(e.address)}</p>
              <StyledButtons>
                  <Button type='danger' onClick={() => this.handleOpen('isDeletePartnerEntity', e.id)}>Delete</Button>
              </StyledButtons>
              {(isDeletePartnerEntity === e.id) && <DeletePartnerEntity partnerId={partner.id} key={e.id} partnerEntityId={e.id} partnerEntityName={e.name} onDone={this.handleDone}/>}
            </Card>
          )}
        </StyledCards>
        <br/>
        <SectionHeader>Dashboard Access</SectionHeader>
        <Table rowKey={r => r.email} columns={PartnerOverview.dashboardUsersColumns} dataSource={partner.dashboardUsers} pagination={false} size='middle'/>
        <br/>
        <SectionHeader>Notifications</SectionHeader>
        <Table rowKey={r => r.email} columns={PartnerOverview.notificationEmailsColumns} dataSource={partner.notifications} pagination={false} size='middle'/>
        <br/>
        <SectionHeader>Delete</SectionHeader>
        <StyledButtons>
            <Button type='danger' onClick={() => this.handleOpen('isDeletePartner')}>Delete partner '{partner.id}'</Button>
        </StyledButtons>
        {isAddPartnerEntity && <AddPartnerEntity partnerId={partner.id} onDone={this.handleDone}/>}
        {isDeletePartner && <DeletePartner partnerId={partner.id} onDone={this.handleDone}/>}
      </Fragment>
    );
  }
}