import React, {Component} from "react";
import PropTypes from "prop-types";
import {Map, Marker} from "mapbox-gl";
import styled from "styled-components";
import {Modal} from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;
  }
  
  & .map-container {
    height: padding: 24px;
  }
`

export default class CoordinatesPicker extends Component {

    static propTypes = {
        coordinates: PropTypes.shape({ lat: PropTypes.number.isRequired, lng: PropTypes.number.isRequired }).isRequired,
        onCancel: PropTypes.func.isRequired,
        onPick: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.initMap = this.initMap.bind(this)

        this.state = {
            coordinates: props.coordinates
        }
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.remove()
        }
    }

    initMap(container) {
        if (!container) {
            return
        }

        const { coordinates } = this.state

        this.map = new Map({
            container: container,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [coordinates.lng, coordinates.lat],
            zoom: 14
        })

        this.marker = new Marker({ color: 'rgb(24, 144, 255)' })
            .setLngLat([coordinates.lng, coordinates.lat])
            .addTo(this.map)

        this.map.on('click', e => {
            this.setState({ coordinates: { lat: e.lngLat.lat, lng: e.lngLat.lng } })
            this.marker.setLngLat(e.lngLat)
        })
    }

    render() {
        const { onCancel, onPick } = this.props
        const { coordinates } = this.state

        return (
            <StyledModal
                centered={true}
                closable={false}
                maskClosable={true}
                okText='Confirm'
                onCancel={onCancel}
                onOk={() => onPick(coordinates)}
                visible={true}
                width='90vw'>
                <div className='map-container'>
                    <div ref={this.initMap} style={{width: '100%', height: 'calc(90vh - 10rem)'}}/>
                </div>
            </StyledModal>
        )
    }
}