import {Alert, Collapse, Table, Tag, Typography, Tooltip} from 'antd'
import React,  { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../components/common/LocalLoader'
import TextLookup from '../../components/common/TextLookup'
import LocationsMap from '../../components/infrastructure/locations/LocationsMap'
import { consoleLocationInspect, consoleLocationsInspect, consoleGetLocations } from '../../logic/network'
import {Link} from "@reach/router";
import {isOnline, formatSince} from "../../logic/utils";
import HelpIcon from "../../components/common/HelpIcon";

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledTag = styled(Tag)`
    width: 31px;
    text-align: center;
`

export default class Locations extends Component {
  columns = [
    {
      title: 'Location ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: v => <Link to={`/infrastructure/locations/${v}`}><StyledCode code>{v}</StyledCode></Link>,
      width: 130
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: 'Partners',
      dataIndex: 'partnersList',
      key: 'partnersList',
      render: partnersList => partnersList ? (
          <span>
            {partnersList.map(partner => {
              return (
                  <Tag color='blue' key={partner}>
                    <Link to={`/infrastructure/partners/${partner}`}>{partner}</Link>
                  </Tag>
              );
            })}
          </span>
      ) : <Tag>Shared</Tag>,
      width: 130
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: 'Operating Mode',
      dataIndex: 'operatingMode',
      key: 'operatingMode',
      sorter: (a, b) => a.operatingMode.localeCompare(b.operatingMode),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: <HelpIcon help='Display: Stalls, Bays or Devices. '>Channels</HelpIcon>,
      dataIndex: 'metadata',
      key: 'stallsInfo',
      render: v => v ?
          <span>
            <Tooltip placement="top" title="Total"><StyledTag color='blue'>{v.stallsTotal}</StyledTag></Tooltip>
            <Tooltip placement="top" title="Available"><StyledTag color='green'>{v.stallsTotal - v.stallsInUse}</StyledTag></Tooltip>
            <Tooltip placement="top" title="In use"><StyledTag color='orange'>{v.stallsInUse}</StyledTag></Tooltip>
          </span>
       : <Tooltip placement="top" title={"We didn't receive metadata from this location"}><i>unknown</i></Tooltip>,
      width: 130
    },
    {
      title: 'Status',
      dataIndex: 'metadata',
      key: 'status',
      render: (value, row, index) => {
        if (row.type !== "container") {
          if (value) {
            if (isOnline(value.lastPing)) {
              return <Tooltip placement="top" title={formatSince(value.lastPing)}><Tag color='green'>Online</Tag></Tooltip>
            } else {
              return <Tooltip placement="top" title={formatSince(value.lastPing)}><Tag color='red'>Offline</Tag></Tooltip>
            }
          } else {
            return <Tooltip placement="top" title={"We didn't receive metadata from this location"}><i>unknown</i></Tooltip>
          }
        }
      },
      width: 130
    },
    {
      title: 'Station adapter',
      dataIndex: 'HALAdapterType',
      key: 'HALAdapterType',
      sorter: (a, b) => a.HALAdapterType ? a.HALAdapterType.localeCompare(b.HALAdapterType): false,
      render: v => <span>{v}</span>,
      width: 130
    }
  ]

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleLookup = this.handleLookup.bind(this)
    this.handleMapClick = this.handleMapClick.bind(this)
  }

  async loadData() {
    return consoleLocationsInspect({
      visibility: 'visible'
    })
  }

  async loadLocations() {
    return consoleGetLocations({})
  }

  onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  async handleLookup(query) {
    try {
      await consoleLocationInspect(query)
      this.props.navigate(`/infrastructure/locations/${query}`)
    } catch (err) {
      if (err.status === 400 || err.status === 404) {
        throw new Error('Location not found.')
      }
      throw err
    }
  }

  handleMapClick(locationId) {
    this.props.navigate(`/infrastructure/locations/${locationId}`)
  }

  render() {
    return (
      <StyledContainer>
        <TextLookup action='Look Up' icon='environment' onSubmit={this.handleLookup} placeholder='Location ID' width='20rem'/>
        <br/>
        <Collapse defaultActiveKey="locations">
          <Collapse.Panel key='locations' header="Locations">
            <div style={{overflowY: "auto"}}>
              <Loads fn={this.loadLocations}  context='infrastructure/locations/list'>
                {({ response, error, isPending, isResolved, isRejected }) => (
                    <Fragment>
                      {isResolved && <StyledTable onChange={this.onChange} rowKey={r => `${r.id}`} columns={this.columns} dataSource={response.locations} size='middle' pagination={{ defaultPageSize: 15}}/>}
                      {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                      {isRejected && <Alert type='error' message={error.message}/>}
                    </Fragment>
                )}
              </Loads>
            </div>
          </Collapse.Panel>
          <Collapse.Panel key='map' header='Map'>
            <Loads fn={this.loadData}  context='infrastructure/locations/map'>
              {({ response, error, isPending, isResolved, isRejected }) => (
                  <Fragment>
                    {isResolved && <LocationsMap locations={response.locations} onClick={this.handleMapClick} onMove={null}/>}
                    {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                    {isRejected && <Alert type='error' message={error.message}/>}
                  </Fragment>
              )}
            </Loads>
          </Collapse.Panel>
        </Collapse>
        <br/>
      </StyledContainer>
    )
  }
}