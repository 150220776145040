import { Link } from '@reach/router'
import { Alert, Table, Typography } from 'antd'
import React, {Component, Fragment} from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../components/common/LocalLoader'
import Severity from '../../components/common/Severity'
import { consoleAlertsInspect } from '../../logic/network'
import { formatDate } from '../../logic/utils'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledAlert = styled(Alert)`
  text-align: center;
  margin: 0 0 24px 0;
`

export default class OpenAlerts extends Component {
  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
  }

  async loadData() {
    return (await consoleAlertsInspect()).alerts
  }

  columns = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      render: v => <Severity severity={v}/>,
      width: 120
    },
    {
      title: 'Type',
      dataIndex: '',
      key: 'type',
      render: (v, r) => {
        if (v.locationBatteryAlert) return `Low Battery (${v.locationBatteryAlert.batteryStatus}%)`
        else return 'N/A'
      },
      width: 200,
    },
    {
      title: 'Location ID',
      dataIndex: '',
      key: 'locationId',
      render: (v, r) => r.locationBatteryAlert ? <Link to={`/infrastructure/locations/${r.locationBatteryAlert.locationId}`}><StyledCode code>{r.locationBatteryAlert.locationId}</StyledCode></Link> : <span>N/A</span>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: v => formatDate(v),
      width: 250
    }
  ]

  render() {
    return (
      <Loads fn={this.loadData}  context='analytics/station-alerts'>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <StyledContainer>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
            <Fragment>
              <StyledAlert message='This page shows all open station alerts.'/>
              <StyledTable rowKey={r => r.id} columns={this.columns} dataSource={response} pagination={false} size='middle' scroll={{y: 'calc(100vh - 15.5rem)'}}/>
            </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </StyledContainer>
        )}
      </Loads>
    )
  }
}