import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: rgb(251, 251, 251);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
  margin: 24px 0 24px 0;

  & p {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 0;
  }   
  
  & > div {
    height: 20rem;
  }
`

export default class DropoffsDistributionChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }

  render() {
    const { data } = this.props

    return (
      <Container>
        <div>
          <ResponsiveBar
            data={data}
            keys={[ 'totalSessions' ]}
            indexBy='hour'
            colors={[ '#36da96' ]}
            margin={{ top: 30, right: 30, bottom: 65, left: 90 }}
            padding={0.3}
            isInteractive={true}
            animate={false}
            tooltip={v => <span>{v.value} charging session(s) started between {v.data.hour.padStart(2, '0')}:00 and {`${(parseInt(v.data.hour) + 1)}`.padStart(2, '0')}:00</span>}
            enableLabel={false}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Hour',
              legendPosition: 'middle',
              legendOffset: 40,
              format: v => `${v}`
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Total Sessions (Count)',
              legendPosition: 'middle',
              legendOffset: -60
            }}
          />
        </div>
      </Container>
    )
  }
}