import { Icon, Popover } from 'antd'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

const StyledPopover = styled(({ className, ...props }) => <Popover {...props} overlayClassName={className} />)`
  max-width: 15rem !important;
`

export default class HelpIcon extends Component {
  render() {
    const { children, help } = this.props

    return (
      <Fragment>
        {children}
        &nbsp;&nbsp;
        <StyledPopover content={help}>
          <Icon type='question-circle' theme='twoTone' twoToneColor='#bbb' />
        </StyledPopover>
      </Fragment>
    )
  }
}