import {Alert, Button, message, Table, Tag, Input, Form} from 'antd'
import React, {Component, Fragment} from 'react'
import update from 'react-addons-update'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import ActionConfirm from '../../components/common/ActionConfirm'
import LocalLoader from '../../components/common/LocalLoader'
import SectionHeader from '../../components/common/SectionHeader'
import AddConsoleUser from './settings/AddConsoleUser'
import AddContractorsUser from './settings/AddContractorsUser'
import config from '../../logic/config'
import {consoleSettingsInspect, consoleSettingsUpdate, consoleSettingsInventoryUpdate, consoleSettingsCleanupBQ} from '../../logic/network'
import EditConsoleUser from "./settings/EditConsoleUser";

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 9rem);
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

const ActionButton = styled(Button)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

export default class Settings extends Component {
  consoleUsersColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
    },
    {
      title: 'Notification Types',
      dataIndex: '',
      key: 'notificationTypes',
      render: (v, r) => {
        const n = []
        if (r.notifications && r.notifications.locationBatteryAlerts) n.push(<Tag key='location-battery-alerts'>location-battery-alerts</Tag>)
        if (n.length === 0) n.push(<Tag key='disabled'>none</Tag>)
        return n
      }
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v) => {
        return (
          <Fragment>
            <ActionButton type='link' size='small' onClick={() => this.handleOpen('editConsoleUser', v)}>Edit</ActionButton>
            &nbsp;&middot;&nbsp;
            <ActionConfirm title={`Remove '${v.email}'?`} help='It will no longer be possible to access this console using this email address.' onConfirm={() => this.handleRemoveConsoleUser(v.email)}><ActionButton type='link' size='small'>Remove</ActionButton></ActionConfirm>
          </Fragment>
        )
      },
      width: 120
    }
  ]

  contractorsUsersColumns = [
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v) => <ActionConfirm title={`Remove '${v.phoneNumber}' (${v.name})?`} help='It will no longer be possible to access the contractors tool using this phone number.' onConfirm={() => this.handleRemoveContractorsUser(v.phoneNumber)}><ActionButton type='link' size='small'>Remove</ActionButton></ActionConfirm>,
      width: 120
    }
  ]

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleRemoveConsoleUser = this.handleRemoveConsoleUser.bind(this)
    this.handleRemoveContractorsUser = this.handleRemoveContractorsUser.bind(this)
    this.handleInventoryUpdate = this.handleInventoryUpdate.bind(this)
    this.handleCleanupBQ = this.handleCleanupBQ.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      refreshCount: 0,
      isAddConsoleUserOpen: false,
      isAddContractorsUserOpen: false,
      editConsoleUser: null,
      isPendingInventoryUpdate: false,
      isInventoryUpdated: false,
      inventoryUpdateMessage: "",
      locationId: "",
    }
  }

  async loadData() {
    return await consoleSettingsInspect()
  }

  handleOpen(target, value) {
    this.setState(update(this.state, {
      [target]: { $set: value ? value : true }
    }))
  }

  handleDone(refresh) {
    this.setState(update(this.state, {
      refreshCount: { $apply: v => refresh ? v + 1 : v },
      isAddConsoleUserOpen: { $set: false },
      isAddContractorsUserOpen: { $set: false },
      editConsoleUser: { $set: null }
    }))
  }

  async handleRemoveConsoleUser(email) {
    try {
      await consoleSettingsUpdate({ removeConsoleUser: email })

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  async handleInventoryUpdate() {
    try {
      this.setState(update(this.state, {
        isPendingInventoryUpdate: { $set: true }
      }))

      let resp = await consoleSettingsInventoryUpdate()

      this.setState(update(this.state, {
        inventoryUpdateMessage: { $set: resp.info },
        isInventoryUpdated: { $set: true },
        isPendingInventoryUpdate: { $set: false }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }


  async handleRemoveContractorsUser(phoneNumber) {
    try {
      await consoleSettingsUpdate({ removeContractorsUser: phoneNumber })

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  async handleCleanupBQ(locaationId) {
    try {
      this.setState(update(this.state, {
        isPendingCleanupBQ: { $set: true }
      }))

      let resp = await consoleSettingsCleanupBQ(locaationId)

      this.setState(update(this.state, {
        cleanupBQMessage: { $set: resp.info },
        locationId: { $set: "" },
        isCleanupBQ: { $set: true },
        isPendingCleanupBQ: { $set: false }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }


  handleChange(field, value) {
    this.setState(update(this.state, {
      [field]: { $set: value }
    }))
  }

  render() {
    const { refreshCount, isAddConsoleUserOpen, isAddContractorsUserOpen, editConsoleUser, isInventoryUpdated, inventoryUpdateMessage, isPendingInventoryUpdate, isCleanupBQ, cleanupBQMessage, isPendingCleanupBQ, locationId } = this.state

    return (
      <Fragment>
        <Loads fn={this.loadData}  context='operations/settings' variables={[refreshCount]}>
          {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
            <Fragment>
              {(isPending || (!response && !isRejected))  && <LocalLoader/>}
              {isResolved &&
                <StyledContainer>
                  <SectionHeader>Console Users</SectionHeader>
                  <p>Anyone with access to these email addresses can access this console.</p>
                  <Table rowKey={r => r.email} columns={this.consoleUsersColumns} dataSource={response.consoleUsers} pagination={false} size='small'/>
                  <br/>
                  <Button type='primary' onClick={() => this.handleOpen('isAddConsoleUserOpen')}>Add Console User</Button>
                  <br/>
                  <br/>
                  <SectionHeader>Contractors Tool Users</SectionHeader>
                  <p>Anyone with access to these phone numbers can access the <a href={`https://contractors.${config.stage}.charge.us`} target='_blank' rel='noopener noreferrer'>contractors tool.</a></p>
                  <Table rowKey={r => r.phoneNumber} columns={this.contractorsUsersColumns} dataSource={response.contractorsUsers} pagination={false} size='small'/>
                  <br/>
                  <Button type='primary' onClick={() => this.handleOpen('isAddContractorsUserOpen')}>Add Contractors Tool User</Button>
                  <br/>
                  <br/>
                  <SectionHeader>Inventory</SectionHeader>
                  {isInventoryUpdated && <p><Alert type='info' message={inventoryUpdateMessage} closable={true} onClose={() => this.setState(update(this.state, {inventoryUpdateMessage: { $set: "" }, isInventoryUpdated: { $set: false }, isPendingInventoryUpdate: { $set: false }}))}/></p>}
                  <p>Update inventory in MsSQL.</p>
                  <Button loading={isPendingInventoryUpdate} type='primary' onClick={() => this.handleInventoryUpdate()}>Update inventory</Button>

                  <br/>
                  <br/>
                  <SectionHeader>Cleanup BigQuery</SectionHeader>
                  {isCleanupBQ && <p><Alert type='info' message={cleanupBQMessage} closable={true} onClose={() => this.setState(update(this.state, {cleanupBQMessage: { $set: "" }, isCleanupBQ: { $set: false }, isPendingCleanupBQ: { $set: false }}))}/></p>}
                  <p>Cleanup location metrics in BigQuery.</p>
                  <p>The metrics for the current day is a working partition and cannot be changed. Therefore the data can be deleted only for the previous days.</p>
                  <Form.Item label='Location ID'>
                    <Input disabled={isPendingCleanupBQ} onChange={e => this.handleChange('locationId', e.target.value)} value={locationId} style={{ width: '30rem' }}/>
                  </Form.Item>
                  <Button loading={isPendingCleanupBQ} type='primary' onClick={() => this.handleCleanupBQ(locationId)}>Cleanup data</Button>
                </StyledContainer>
              }
              {isRejected && <StyledAlert type='error' message={error.message}/>}
            </Fragment>
          )}
        </Loads>
        {isAddConsoleUserOpen && <AddConsoleUser onDone={this.handleDone}/>}
        {editConsoleUser && <EditConsoleUser consoleUser={editConsoleUser} onDone={this.handleDone}/>}
        {isAddContractorsUserOpen && <AddContractorsUser onDone={this.handleDone}/>}
      </Fragment>
    )
  }
}