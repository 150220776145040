import { Spin } from 'antd'
import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
  padding-top: 24px;
`

export default class LocalLoader extends Component {
  render() {
    return (
      <Container>
        <Spin/>
      </Container>
    )
  }
}