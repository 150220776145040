import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledPopconfirm = styled(({ className, ...props }) => <Popconfirm {...props} overlayClassName={className} />)`
  width: 15rem;
  
  & .ant-popover-message-title {
    margin: 0;
    padding: 0;
  }
`

export default class ActionConfirm extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    help: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    const { children, help, onConfirm, title } = this.props

    return (
      <StyledPopconfirm
        icon={null}
        onConfirm={onConfirm}
        placement='topRight'
        title={<div><p>{title}</p><p><i>{help}</i></p></div>}>
        {children}
      </StyledPopconfirm>
    )
  }
}