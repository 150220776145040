import { Collapse } from 'antd'
import JSONTree from 'react-json-tree'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import { base16theme } from '../../../../logic/config'

const StyledCollapsePanel = styled(Collapse.Panel)`
  & .ant-collapse-content-box {
    padding: 8px 16px;
  }
  
  & ul {
    border: 0 !important;
    margin: 0 !important;
  }
`

export default class LocationDiagnostics extends Component {
  static propTypes = {
    cognitoUser: PropTypes.object.isRequired,
    stripeCustomer: PropTypes.object,
    stripePaymentMethods: PropTypes.array,
  }

  render() {
    const { cognitoUser, stripeCustomer, stripePaymentMethods } = this.props

    return (
      <Collapse defaultExp defaultActiveKey={['cognitoUser', 'stripeCustomer', 'stripePaymentMethods']}>
        <StyledCollapsePanel key='cognitoUser' header='Cognito User'>
          <JSONTree data={cognitoUser} hideRoot invertTheme={false} theme={base16theme}/>
        </StyledCollapsePanel>
        {stripeCustomer &&
          <StyledCollapsePanel key='stripeCustomer' header='Stripe Customer'>
            <JSONTree data={stripeCustomer} hideRoot invertTheme={false} theme={base16theme}/>
          </StyledCollapsePanel>
        }
        {stripePaymentMethods &&
          <StyledCollapsePanel key='stripePaymentMethods' header='Stripe Payment Methods'>
            <JSONTree data={stripePaymentMethods} hideRoot invertTheme={false} theme={base16theme}/>
          </StyledCollapsePanel>
        }
      </Collapse>
    )
  }
}

// brewer