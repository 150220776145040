import { Link } from '@reach/router'
import { Alert, Button, message, Table, Typography } from 'antd'
import countryCodeEmoji from 'country-code-emoji'
import React,  { Component, Fragment } from 'react'
import update from 'react-addons-update'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import ActionConfirm from '../../components/common/ActionConfirm'
import LocalLoader from '../../components/common/LocalLoader'
import { consoleInvoicesInspect, consoleInvoicesSetPaid } from '../../logic/network'

const StyledButton = styled(Button)`
  padding-left: 0 !important;
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledAlert = styled(Alert)`
  text-align: center;
  margin: 0 0 24px 0;
`

export default class PendingInvoices extends Component {
  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleMarkInvoicePaid = this.handleMarkInvoicePaid.bind(this)

    this.state = {
      refreshCount: 0,
    }
  }

  columns = [
    {
      title: 'Partner ID',
      dataIndex: 'partnerId',
      key: 'partnerId',
      render: v => <Link to={`/infrastructure/partners/${v}`}><StyledCode code>{v}</StyledCode></Link>,
      width: 130
    },
    {
      title: 'Billing Month',
      dataIndex: 'formattedYearMonth',
      key: 'formattedYearMonth',
      width: 130
    },
    {
      title: 'Recipient Entity',
      dataIndex: 'partnerEntityName',
      key: 'partnerEntityName',
      render: (v, r) => <span>{v}&nbsp;&nbsp;{countryCodeEmoji(r.partnerEntityCountryCode)}</span>,
    },
    {
      title: 'Total',
      dataIndex: 'formattedTotal',
      key: 'formattedTotal',
      width: 150
    },
    {
      title: 'PDF',
      dataIndex: 'signedDownloadLink',
      key: 'signedDownloadLink',
      render: (v) => <a target='_blank' rel='noopener noreferrer' href={v}>Link</a>,
      width: 100
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v, r) => <ActionConfirm title='Set invoice as paid?' help='The invoice will be set as paid and removed from this page.' onConfirm={() => this.handleMarkInvoicePaid(r.partnerId, r.partnerEntityId, r.formattedYearMonth)}><StyledButton type='link' size='small'>Set Paid</StyledButton></ActionConfirm>,
      width: 100
    }
  ]

  async loadData() {
    return (await consoleInvoicesInspect(false)).invoices
  }

  async handleMarkInvoicePaid(partnerId, partnerEntityId, yearMonth) {
    try {
      await consoleInvoicesSetPaid(partnerId, partnerEntityId, yearMonth)

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  render() {
    const { refreshCount } = this.state

    return (
      <Loads fn={this.loadData}  context='analytics/pending-invoices' variables={[refreshCount]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <StyledContainer>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
              <Fragment>
                <StyledAlert message='This page shows all unpaid invoices.'/>
                <StyledTable rowKey={r => `${r.partnerId}-${r.partnerEntityId}-${r.formattedYearMonth}`} columns={this.columns} dataSource={response} pagination={false} size='middle' scroll={{y: 'calc(100vh - 15.5rem)'}}/>
              </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </StyledContainer>
        )}
      </Loads>
    )
  }
}