import {Button, Form, Input, Modal} from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import {consolePartnersDelete} from '../../../logic/network'

const StyledContainer = styled.div`
  padding: 0 0 16px 0;
  text-align: center;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

const StyledInput = styled(Input)`
  width: 20rem;
`


export default class DeletePartner extends Component {
  static propTypes = {
    partnerId: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      loading: false,
      partnerId: '',
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmit(e) {
    const { partnerId } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))

    if (partnerId !== this.props.partnerId) {
      this.setState(update(this.state, {
        errorMessage: { $set: "Invalid partner ID!" },
        loading: { $set: false }
      }))
      return
    }

    try {
      const resp = await consolePartnersDelete(this.props.partnerId)
      console.log("resp=", resp)
      this.props.onDone(true)
      window.location = "/infrastructure/partners";
    } catch (err) {
      const errorMessage = err.message
      this.setState(update(this.state, {
        errorMessage: { $set: errorMessage },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    this.setState(update(this.state, {
      errorMessage: { $set: null },
      [field]: { $set: value }
    }))
  }

  render() {
    const { errorMessage, loading, partnerId } = this.state

    return (
      <Modal centered closable={true} footer={null} title='Delete partner' visible={true} onCancel={this.handleCancel} transitionName='none'>
        <p>Be careful while remove partner from the system!</p>
        <StyledContainer>
          <StyledForm layout='inline' onSubmit={this.handleSubmit}>
            <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
              <StyledInput disabled={loading} onChange={(e) => this.handleChange('partnerId', e.target.value)} placeholder='Id' value={partnerId}/>
            </Form.Item>
            <Form.Item>
              <Button disabled={(!partnerId.length)} htmlType='submit' loading={loading} type='danger'>Delete</Button>
            </Form.Item>
          </StyledForm>
        </StyledContainer>
      </Modal>
    )
  }
}