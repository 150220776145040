import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { bugsnagClient } from './logic/config'

const ErrorBoundary = bugsnagClient.getPlugin('react')
ReactDOM.render(<ErrorBoundary><App /></ErrorBoundary>, document.getElementById('root'));
serviceWorker.unregister();
