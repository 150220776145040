import { Alert, Button, Collapse, Tag } from 'antd'
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { consoleDiagnosticsPerform, consoleLocationInspect } from '../../../../logic/network'

const StyledAlert = styled(Alert)`
  padding-right: 9rem !important;
`

export default function StationTest({ location }) {
    const stalls = [ ...Array(location.reservables.length).keys() ].map((_, i) => i+1)

    return (
      <Collapse defaultActiveKey={['system'].concat(stalls.map(i => 'stall' + i))}>
        <Collapse.Panel key='system' header='System'>
          <SystemTest location={location}/>
        </Collapse.Panel>
        {stalls.map(stallNumber =>
          <Collapse.Panel key={`stall${stallNumber}`} header={`Stall ${stallNumber}`}>
            <StallTest location={location} stallNumber={stallNumber}/>
          </Collapse.Panel>
        )}
      </Collapse>
    )
}

function SystemTest({ location }) {
  const [ loading, setLoading ] = useState(false)
  const [ done, setDone ] = useState(false)
  const [ error, setError ] = useState(null)

  const doRun = async () => {
    let resp

    try {
      setLoading(true)
      resp = await consoleLocationInspect(location.id, { includeBalenaDevice: true })
      console.log(resp)
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }

    if (!resp.balenaDevice.isOnline) {
      return setError('The station is not online.')
    }

    return setDone(true)
  }

  return (
    <Fragment>
      {!done && error === null && <Button loading={loading} type='primary' onClick={doRun}>Perform Test</Button>}
      {done && <Tag color='green'>Test Passed</Tag>}
      {error !== null && <StyledAlert closeText='Reset and Retry' message={error} onClose={() => setError(null)} type='error'/>}
    </Fragment>
  )
}

function StallTest({ location, stallNumber }) {
  const [ loading, setLoading ] = useState(false)
  const [ step, setStep ] = useState({ step: 'ready' })
  const [ error, setError ] = useState(null)

  const doRunEnable = async() => {
    try {
      setLoading(true)
      const resp = await consoleDiagnosticsPerform({
        initiateDropoffByLocationId: {
          locationId: location.id,
          stallNumber
        }
      })
      console.log(resp)
      return setStep({ step: 'enable' })
    } catch (err) {
      console.error(err)
      if (err.detail === 'stall-not-available') {
        return setError('The stall is not available. Resolution: if a scooter is plugged in, unplug it, otherwise wait for 3m for the previously initiated drop-off to expire.')
      } else {
        return setError(err.message)
      }

    } finally {
      setLoading(false)
    }
  }

  const doRunVerifyPlugIn = async() => {
    let resp

    try {
      setLoading(true)
      resp = await consoleLocationInspect(location.id, {
        includeActiveReservations: true,
        includeSnapshot: true
      })
      console.log(resp)
    } catch (err) {
      console.error(err)
      return setError(err.message)
    } finally {
      setLoading(false)
    }

    const stallIndex = stallNumber - 1
    const activeRsv = resp.activeReservations.find(rsv => rsv.reservableId === location.reservables[stallIndex].id)
    console.log(activeRsv)

    if (!activeRsv) {
      return setError('No active reservation was found for this stall. Resolution: this is an unexpected error which is not caused by the station. Try again in a few minutes.')
    }

    if (activeRsv.consoleExpired) {
      return setError('The scooter was not plugged in within 3m from enabling the stall. Resolution: try again, but be quicker.')
    }

    if (activeRsv.consoleProvisionalAt !== null) {
      return setError('The scooter is not plugged in or is not detected by the station. Resolution: check connections and harnesses, if the issue persists check with the software team.')
    }

    if (!resp.stationSnapshot.stallsEnabled[stallIndex] || !resp.stationSnapshot.powerMeters[stallIndex]) {
      return setError('The station appears to believe that this stall is not enabled. Resolution: check with the software team.')
    }

    const powerMeter = resp.stationSnapshot.powerMeters[stallIndex]

    if (powerMeter.voltage < 30000 || powerMeter.voltage > 50000) {
      return setError(`The voltage reading for this stall (${powerMeter.voltage} mV) appears to be off. Resolution: check with the software team.`)
    }

    if (powerMeter.current < 500 || powerMeter.current > 3000) {
      return setError(`The current reading for this stall (${powerMeter.current} mA) appears to be off. Resolution: check with the software team.`)
    }

    return setStep({ step: 'done' })
  }

  return (
    <Fragment>
      {step.step === 'ready' && error === null &&
        <Fragment>
          <ol>
            <li>Verify no scooter is plugged in on this stall.</li>
            <li>Click <i>Enable Stall.</i></li>
          </ol>
          <Button loading={loading} type='primary' onClick={doRunEnable}>Enable Stall</Button>
        </Fragment>
      }
      {
        step.step === 'enable' && error === null &&
          <Fragment>
            <ol>
              <li>If this station has LED indicators, verify that the LED for this stall is now on and white.</li>
              <li>Plug a scooter in at this stall.</li>
              <li>Verify that the scooter is charging.</li>
              <li>If this station has LED indicators, verify that the LED for this stall is still on and a color shade between red and green.</li>
              <li>Click <i>Verify Plug-in.</i></li>
            </ol>
            <Button loading={loading} type='primary' onClick={doRunVerifyPlugIn}>Verify Plug-in</Button>
          </Fragment>
      }
      {step.step === 'done' && error === null && <Tag color='green'>Test Passed</Tag>}
      {error !== null && <StyledAlert closeText='Reset and Retry' message={error} onClose={() => { setStep({ step: 'ready' }); setError(null) }} type='error'/>}
    </Fragment>
  )
}
