import { match } from '@reach/router/lib/utils'
import { Button, Icon, Layout, Menu, PageHeader } from 'antd'
import { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import styled from 'styled-components'
import logoUrl from '../assets/logo.png'

const StyledContainer = styled(Layout)`
  min-height: 100vh;
  min-width: 1200px;
  padding-left: 200px;
`

const StyledSider = styled(Layout.Sider)`
  height: 100vh;
  overflow: auto;
  left: 0;
  position: fixed;
`

const StyledLogo = styled.div`
  background-image: url(${logoUrl});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  margin: 16px;
`

const StyledContent = styled(Layout.Content)`
  background: #fff;
  margin: 0;
  padding: 24px 0 0 0;
`

const StyledHeading = styled(PageHeader)`
  padding: 24px 0;

  & .ant-breadcrumb {
    position: absolute;
    top: 22px;
  }
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px 24px;
  z-index: 1000;
`

const StyledUserInfo = styled.span`
  display: inline-block;
  vertical-align: top;
  padding-top: 1px;
`

export default class Root extends Component {
  getBreadcrumbs() {
    const { location } = this.props

    const breadcrumbs = ['Charge']
    const analyticsStations = match('/analytics/stations', location.pathname)
    const infrastructureCreateLocation = match('/infrastructure/location/create', location.pathname)
    const infrastructureLocations = match('/infrastructure/locations', location.pathname)
    const infrastructureLocation = match('/infrastructure/locations/:locationId', location.pathname)
    const infrastructureJuicerAppUsers = match('/infrastructure/juicer-app-users', location.pathname)
    const infrastructureJuicerAppUser = match('/infrastructure/juicer-app-users/:userId', location.pathname)
    const infrastructurePartners = match('/infrastructure/partners', location.pathname)
    const infrastructurePartner = match('/infrastructure/partners/:partnerId', location.pathname)
    const operationsOpenAlerts = match('/operations/open-alerts', location.pathname)
    const operationsPendingInvoices = match('/operations/pending-invoices', location.pathname)
    const operationsProvisioning = match('/operations/provisioning', location.pathname)
    const operationsSettings = match('/operations/settings', location.pathname)

    if (analyticsStations) {
      breadcrumbs.push('Analytics')

      if (analyticsStations) {
        breadcrumbs.push('Stations')
      }
    } else if (infrastructureLocations || infrastructureLocation || infrastructureJuicerAppUsers || infrastructureJuicerAppUser || infrastructurePartners || infrastructurePartner || infrastructureCreateLocation) {
      breadcrumbs.push('Infrastructure')

      if (infrastructureCreateLocation) {
        breadcrumbs.push('Create Location')
      }

      if (infrastructureLocations || infrastructureLocation) {
        breadcrumbs.push('Locations')

        if (infrastructureLocation) {
          breadcrumbs.push(infrastructureLocation.params.locationId)
        }
      } else if (infrastructureJuicerAppUsers || infrastructureJuicerAppUser) {
        breadcrumbs.push('Juicer App Users')

        if (infrastructureJuicerAppUser) {
          breadcrumbs.push(infrastructureJuicerAppUser.params.userId)
        }
      } else if (infrastructurePartners || infrastructurePartner) {
        breadcrumbs.push('Partners')

        if (infrastructurePartner) {
          const partnerId = infrastructurePartner.params.partnerId
          breadcrumbs.push(partnerId[0].toUpperCase() + partnerId.slice(1))
        }
      }
    } else if (operationsOpenAlerts || operationsPendingInvoices || operationsProvisioning || operationsSettings) {
      breadcrumbs.push('Operations')

      if (operationsOpenAlerts) {
        breadcrumbs.push('Open Alerts')
      } else  if (operationsPendingInvoices) {
        breadcrumbs.push('Pending Invoices')
      } else if (operationsProvisioning) {
        breadcrumbs.push('Provisioning')
      } else if (operationsSettings) {
        breadcrumbs.push('Settings')
      }
    } else {
      breadcrumbs.push('Not Found')
    }

    return breadcrumbs.map(b => { return { breadcrumbName: b }})
  }

  render() {
    const { auth, children, navigate } = this.props
    const selectedKeys = this.props['*'] ? [this.props['*'].split('/').slice(0, 2).join('/')] : []

    return (
      <StyledContainer path='/'>
        <StyledSider>
          <StyledLogo alt='Charge'/>
          <Menu theme='dark' mode='inline' selectedKeys={selectedKeys}>
            <Menu.ItemGroup key='analytics' title='Analytics'>
              <Menu.Item key='analytics/stations' onClick={() => navigate('/analytics/stations')}><span><Icon type='environment'/></span>Stations</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key='infrastructure' title='Infrastructure'>
              <Menu.Item key='infrastructure/locations' onClick={() => navigate('/infrastructure/locations')}><span><Icon type='environment'/><span>Locations</span></span></Menu.Item>
              <Menu.Item key='infrastructure/location' onClick={() => navigate('/infrastructure/location/create')}><span><Icon type='plus'/><span>Create Location</span></span></Menu.Item>
              <Menu.Item key='infrastructure/juicer-app-users' onClick={() => navigate('/infrastructure/juicer-app-users')}><span><Icon type='user'/><span>Juicer App Users</span></span></Menu.Item>
              <Menu.Item key='infrastructure/partners' onClick={() => navigate('/infrastructure/partners')}><span><Icon type='team'/><span>Partners</span></span></Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key='operations' title='Operations'>
              <Menu.Item key='operations/open-alerts' onClick={() => navigate('/operations/open-alerts')}><span><Icon type='alert'/><span>Open Alerts</span></span></Menu.Item>
              <Menu.Item key='operations/pending-invoices' onClick={() => navigate('/operations/pending-invoices')}><span><Icon type='dollar'/><span>Pending Invoices</span></span></Menu.Item>
              <Menu.Item key='operations/provisioning' onClick={() => navigate('/operations/provisioning')}><span><Icon type='deployment-unit'/><span>Provisioning</span></span></Menu.Item>
              <Menu.Item key='operations/settings' onClick={() => navigate('/operations/settings')}><span><Icon type='control'/><span>Settings</span></span></Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </StyledSider>
        <StyledLayout>
          <StyledHeading breadcrumb={{itemRender: (route) => <span>{route.breadcrumbName}</span>, routes: this.getBreadcrumbs()}} extra={[
            <StyledUserInfo key='userInfo'>{auth.userInfo.attributes.email}</StyledUserInfo>,
            <Button key='signOut' onClick={() => Auth.signOut()} size='small'>Sign Out</Button>
          ]} title={null}/>
          <StyledContent>
            {children}
          </StyledContent>
        </StyledLayout>
      </StyledContainer>
    )
  }
}