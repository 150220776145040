import { Auth } from 'aws-amplify'
import uuid from 'uuid/v4'
import { InvalidCodeError, InvalidEmailError, UnexpectedError } from './errors'

export async function initiateSignIn(email) {
    try {
        await Auth.signUp( {
            username: email,
            password: uuid(),
        })
    } catch (err) {
        if (err.code === 'InvalidParameterException') {
            throw new InvalidEmailError(email, err)
        }

        if (!err.code || err.code !== 'UsernameExistsException') {
            throw new UnexpectedError(err)
        }
    }

    try {
        return await Auth.signIn(email)
    } catch (err) {
        throw new UnexpectedError(err)
    }
}

export async function completeSignIn(challenge, code) {
    try {
        await Auth.sendCustomChallengeAnswer(challenge, code)
        await Auth.currentSession()
        return await Auth.currentUserInfo()
    } catch (err) {
        if (err === 'No current user') {
            throw new InvalidCodeError(false, err)
        }

        if (err.code && err.code === 'NotAuthorizedException') {
            throw new InvalidCodeError(true, err)
        }

        throw new UnexpectedError(err)
    }
}

export async function getCurrentUserInfo() {
    try {
        return await Auth.currentUserInfo()
    } catch (err) {
        if (err === 'No current user') {
            return null
        }

        throw new UnexpectedError(err)
    }
}

export async function getToken() {
    try {
        const session = await Auth.currentSession()
        return '' + session.idToken.jwtToken
    } catch (err) {
        // TODO: Distinguish unauthorized vs. unexpected error.
        console.warn(err)
        await Auth.signOut()
    }
}