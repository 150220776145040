import { Button, Result } from 'antd'
import React, { Component } from 'react'

export default class GlobalNotFound extends Component {
  render() {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Page Not Found'
        extra={<Button type="primary" onClick={() => this.props.navigate('/')}>Back Home</Button>}
      />
    )
  }
}