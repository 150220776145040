import { Col, Descriptions, Row, Tag, Typography } from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { googleMapsUrl, staticMapUrl } from '../../../../logic/utils'
import SectionHeader from '../../../common/SectionHeader'

const StyledMap = styled.img`
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
  height: 16.9rem;
  object-fit: cover;
  width: 100%;
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledTag = styled(Tag)`
  text-align: center;
  width: 7rem;
`

const StyledDescriptions = styled(Descriptions)`
  & th {
    width: 8rem;
  }
`

export default class StationOverview extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    stationStatus: PropTypes.object
  }

  static ledToColor(led) {
    if (led.R === 1 && led.G === 1 && led.B === 1) {
      return 'blue' // "simulate" white
    }

    const [ r, g, b ] = [ Math.floor(led.R*255.0+0.5) % 256, Math.floor(led.G*255.0+0.5) % 256,  Math.floor(led.B*255.0+0.5) % 256 ]
    return `rgb(${r}, ${g}, ${b}`
  }

  render() {
    const { location } = this.props

    return (
      <Fragment>
        <SectionHeader>Overview</SectionHeader>
        <Row gutter={16}>
          <Col span={14}>
            <StyledDescriptions bordered column={1}>
              <Descriptions.Item label='Container ID'><StyledCode code>{location.id}</StyledCode></Descriptions.Item>
              <Descriptions.Item label='Locale'> {location.countryCode} · {location.timeZone}{location.currency ? ` · ${location.currency.code}` : ''}</Descriptions.Item>
              <Descriptions.Item label='Address'><a href={googleMapsUrl(location.coordinates)} target='_blank' rel='noopener noreferrer'>{location.address}</a></Descriptions.Item>
              <Descriptions.Item label='Slots'>start at {location.slotting.fixedSlotDayStart} &middot; {moment.duration(location.slotting.fixedSlotDayDurationSeconds, 'seconds').format('h [hours]')}</Descriptions.Item>
              <Descriptions.Item label='Discounts'>
                {location.slotting.fixedSlotDayMultipleSlotsDiscount
                  ? `Book ${location.slotting.fixedSlotDayMultipleSlotsDiscount.minimumNumberOfSlots}+ slots, ${location.slotting.fixedSlotDayMultipleSlotsDiscount.discount}% discount`
                  : 'None'
                }
              </Descriptions.Item>
            </StyledDescriptions>
          </Col>
          <Col span={10}>
            <a href={googleMapsUrl(location.coordinates)} target='_blank' rel='noopener noreferrer'><StyledMap src={staticMapUrl(location.coordinates, '500x700')}/></a>
          </Col>
        </Row>
        <br/>
        <SectionHeader>Charging Bays</SectionHeader>
        <StyledDescriptions bordered column={2}>
          {location.consoleReservables.map((rsb, i) =>
            <Descriptions.Item key={rsb.id} label={'Bay ' + `${i+1}`.padStart(2, '0')}>
              <Fragment>{rsb.currentAvailability === 'available' ? <StyledTag color='blue'>Available</StyledTag> : <StyledTag color='red'>Reserved</StyledTag>}</Fragment>
              <Tag>Cap. {rsb.containerBayCapacity}</Tag>
            </Descriptions.Item>
          )}
        </StyledDescriptions>
      </Fragment>
    )
  }
}