import { Button, Checkbox, Form, Modal } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import { consoleSettingsUpdate } from '../../../logic/network'

const StyledForm = styled(Form)`
  & .ant-form-item {
    margin: 0;
  }
`

const StyledFormFooter = styled(Form.Item)`
  padding-top: 16px;
  
  & button {
    width: 6rem;
  }
  
  & .ant-form-explain {
    margin-top: 16px;
  }
`


export default class EditConsoleUser extends Component {
  static propTypes = {
    consoleUser: PropTypes.object.isRequired,
    onDone: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errorMessage: null,
      loading: false,
      value: props.consoleUser.notifications ? props.consoleUser.notifications : {}
    }
  }

  handleCancel() {
    this.props.onDone(false)
  }

  async handleSubmit(e) {
    const { consoleUser } = this.props
    const { value } = this.state
    e.preventDefault()

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      loading: { $set: true }
    }))

    try {
      await consoleSettingsUpdate({
        updateConsoleUserNotifications: {
          email: consoleUser.email,
          notifications: value
        }
      })
      this.props.onDone(true)
    } catch (err) {
      this.setState(update(this.state, {
        errorMessage: { $set: err.message },
        loading: { $set: false }
      }))
    }
  }

  handleChange(field, value) {
    console.log('xxx', field, value)

    this.setState(update(this.state, {
      errorMessage: { $set: null },
      value: {
        [field]: { $set: value }
      }
    }))
  }

  render() {
    const { consoleUser } = this.props
    const { errorMessage, loading, value } = this.state

    return (
      <Modal centered closable={true} footer={null} title={<span>Edit Console User &middot; {consoleUser.email}</span>} visible={true} onCancel={this.handleCancel} transitionName='none'>
        <StyledForm layout='horizontal' onSubmit={this.handleSubmit}>
          <Form.Item label='Notification Types'>
            <Checkbox disabled={loading} onChange={(e) => this.handleChange('locationBatteryAlerts', e.target.checked)} checked={value.locationBatteryAlerts}>Location Battery Alerts</Checkbox>
          </Form.Item>
          <StyledFormFooter help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
            <Button htmlType='submit' loading={loading} type='primary'>Save</Button>
          </StyledFormFooter>
        </StyledForm>
      </Modal>
    )
  }
}