import { Link } from '@reach/router'
import { Alert, Table, Typography } from 'antd'
import React,  { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../components/common/LocalLoader'
import { consoleLocationsInspect } from '../../logic/network'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledAlert = styled(Alert)`
  text-align: center;
  margin: 0 0 24px 0;
`


export default class Provisioning extends Component {
    constructor(props) {
        super(props)

        this.loadData = this.loadData.bind(this)

        this.state = {
            refreshCount: 0,
        }
    }

    columns = [
        {
            title: 'Location ID',
            dataIndex: 'id',
            key: 'id',
            render: v => <Link to={`/infrastructure/locations/${v}`}><StyledCode code>{v}</StyledCode></Link>,
            width: 130
        },
        {
            title: 'Station Type',
            dataIndex: 'HALAdapterType',
            key: 'HALAdapterType',
            render: v => <span>{v}</span>,
            width: 130
        },
        {
            title: 'Station Mode',
            dataIndex: 'operatingMode',
            key: 'operatingMode',
            render: v => <span>{v}</span>,
            width: 130
        }
    ]

    async loadData() {
        return (await consoleLocationsInspect({
            visibility: 'hidden'
        })).locations
    }

    render() {
        const { refreshCount } = this.state

        return (
            <Loads fn={this.loadData}  context='operations/provisioning' variables={[refreshCount]}>
                {({ response, error, isPending, isResolved, isRejected }) => (
                    <StyledContainer>
                        {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                        {isResolved &&
                        <Fragment>
                            <StyledAlert message='This page shows all stations awaiting provisioning.'/>
                            <StyledTable rowKey={r => `${r.id}`} columns={this.columns} dataSource={response} pagination={false} size='middle' scroll={{y: 'calc(100vh - 15.5rem)'}}/>
                        </Fragment>
                        }
                        {isRejected && <Alert type='error' message={error.message}/>}
                    </StyledContainer>
                )}
            </Loads>
        )
    }
}