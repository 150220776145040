import {Alert, Button, Table, Typography} from 'antd'
import PropTypes from 'prop-types'
import React, {Component, Fragment} from 'react'
import styled from 'styled-components'
import {Loads} from "react-loads";
import {consolePrivateLocations} from "../../../../logic/network";
import LocalLoader from "../../../common/LocalLoader";
import {Link} from "@reach/router";
import AddPrivateLocation from "../../../../containers/infrastructure/partners/AddPrivateLocation";
import update from "react-addons-update";
import DeletePrivateLocation from "../../../../containers/infrastructure/partners/DeletePrivateLocation";

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledButtons = styled.div`
  padding: 0 24px 24px 0px;
  overflow-y: auto;
`

export default class PartnerPrivateLocations extends Component {
    static propTypes = {
        partner: PropTypes.object.isRequired
    }

    columns = [
        {
            title: 'Location ID',
            dataIndex: 'id',
            key: 'id',
            render: v => <Link to={`/infrastructure/locations/${v}`}><StyledCode code>{v}</StyledCode></Link>,
            width: 130
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: v => <span>{v}</span>,
            width: 130
        },
        {
            title: 'Station Type',
            dataIndex: 'HALAdapterType',
            key: 'HALAdapterType',
            render: v => <span>{v}</span>,
            width: 130
        },
        {
            title: 'Operating Mode',
            dataIndex: 'operatingMode',
            key: 'operatingMode',
            render: v => <span>{v}</span>,
            width: 130
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: v => <Button type='danger' onClick={() => this.handleOpen('isDeletePrivateLocation', v)}>Delete</Button>,
            width: 130
        }
    ]

    async loadData(partner) {
        try {
            return await consolePrivateLocations(partner.id)
        } catch (err) {
            if (err.status === 400 || err.status === 404) {
                throw new Error('Partner not found.')
            }
            throw err
        }
    }

    constructor(props) {
        super(props)

        this.handleOpen = this.handleOpen.bind(this)
        this.handleDone = this.handleDone.bind(this)

        this.state = {
            isAddPrivateLocation: false,
            isDeletePrivateLocation: false,
        }
    }

    handleOpen(target, value) {
        this.setState(update(this.state, {
            [target]: { $set: value ? value : true }
        }))
    }

    handleDone(refresh) {
        this.setState(update(this.state, {
            isAddPrivateLocation: { $set: false },
            isDeletePartner: { $set: false },
            isDeletePartnerEntity: { $set: false },
            isDeletePrivateLocation: { $set: false }
        }))
        if (refresh) {
            window.location.reload(refresh);
        }
    }

    render() {
        const { partner } = this.props
        const { isAddPrivateLocation, isDeletePrivateLocation } = this.state
        return (
            <Loads fn={this.loadData} context='infrastructure/partners/partner' variables={[partner]}>
                {({ response, error, isPending, isResolved, isRejected }) => (
                    <StyledContainer>
                        {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                        {isResolved && response.locations &&
                        <Fragment>
                            <StyledButtons>
                                <Button type='primary' onClick={() => this.handleOpen('isAddPrivateLocation')}>Add private location</Button>
                            </StyledButtons>
                            <StyledTable rowKey={r => `${r.id}`} columns={this.columns} dataSource={response.locations} pagination={false} size='middle'/>
                        </Fragment>
                        }
                        {isDeletePrivateLocation && <DeletePrivateLocation partnerId={partner.id} locationId={isDeletePrivateLocation} onDone={this.handleDone}/>}
                        {isAddPrivateLocation && <AddPrivateLocation partnerId={partner.id} onDone={this.handleDone}/>}
                        {isRejected && <Alert type='error' message={error.message}/>}
                    </StyledContainer>
                )}
            </Loads>
        );
    }
}