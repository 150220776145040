import { Alert, Tabs } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import JuicerAppUserDiagnostics from './juicer-app-user/JuicerAppUserDiagnostics'
import JuicerAppUserReservations from './juicer-app-user/JuicerAppUserReservations'
import LocalLoader from '../../../components/common/LocalLoader'
import JuicerAppUserOverview from '../../../components/infrastructure/juicer-app-users/juicer-app-user/JuicerAppUserOverview'
import { consoleUserInspect } from '../../../logic/network'

const StyledTabsContainer = styled.div`
  & .ant-tabs-bar {
    padding-left: 24px !important;
  }
 
  & .ant-tabs-tab {
    margin-right: 8px !important;
  }
  
  & .ant-tabs-tabpane {
    padding-top: 0.5rem;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 12.5rem);
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

export default class JuicerAppUser extends Component {
  static propTypes = {
    userId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
  }

  async loadData(userId) {
    try {
      return await consoleUserInspect(userId, {
        includeStripeCustomer: true,
        includeStripePaymentMethods: true
      })
    } catch (err) {
      if (err.status === 404) {
        throw new Error('Juicer App user not found.')
      }
      throw err
    }
  }

  render() {
    const { userId } = this.props

    return (
      <Loads fn={this.loadData}  context='infrastructure/juicer-app-users/juicer-app-user' variables={[userId]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
              <StyledTabsContainer>
                <Tabs type='card'>
                  <Tabs.TabPane key='user' tab='User'>
                    <StyledContainer>
                      <JuicerAppUserOverview cognitoUser={response.cognitoUser} stripeCustomer={response.stripeCustomer} stripePaymentMethods={response.stripePaymentMethods}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                  <Tabs.TabPane key='userReservations' tab='Bay Reservations'>
                    <StyledContainer>
                      <JuicerAppUserReservations cognitoUser={response.cognitoUser}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                  <Tabs.TabPane key='diagnostics' tab='Diagnostics'>
                    <StyledContainer>
                      <JuicerAppUserDiagnostics cognitoUser={response.cognitoUser} stripeCustomer={response.stripeCustomer} stripePaymentMethods={response.stripePaymentMethods}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                </Tabs>
              </StyledTabsContainer>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}