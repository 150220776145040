import { Table } from 'antd'
import countryCodeEmoji from 'country-code-emoji'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

export default class PartnerInvoices extends Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired
  }

  static columns = [
    {
      title: 'Billing Month',
      dataIndex: 'formattedYearMonth',
      key: 'formattedYearMonth',
      width: 190
    },
    {
      title: 'Recipient Entity',
      dataIndex: 'partnerEntityName',
      key: 'partnerEntityName',
      render: (v, r) => <span>{v}&nbsp;&nbsp;{countryCodeEmoji(r.partnerEntityCountryCode)}</span>
    },
    {
      title: 'Total',
      dataIndex: 'formattedTotal',
      key: 'formattedTotal',
      width: 190
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      render: (v) => v ? 'Yes' : 'No',
      width: 190
    },
    {
      title: 'PDF',
      dataIndex: 'signedDownloadLink',
      key: 'signedDownloadLink',
      render: (v) => <a target='_blank' rel='noopener noreferrer' href={v}>Link</a>,
      width: 190
    }
  ]

  render() {
    const { invoices } = this.props

    return (
      <StyledTable rowKey={r => `${r.partnerId}-${r.partnerEntityId}-${r.formattedYearMonth}`} columns={PartnerInvoices.columns} dataSource={invoices} pagination={false} size='middle' scroll={{y: 'calc(100vh - 15.5rem)'}}/>
    );
  }
}