import {Alert, Button, Form, Input, InputNumber, Select, Switch, Col, Row} from 'antd'
import React,  { Component, Fragment} from 'react'
import styled from 'styled-components'
import update from "react-addons-update";
import {consoleCreateLocation, consoleGetBalenaDevices, consolePartnersInspect} from "../../logic/network";
import CoordinatesPicker from "./locations/location/CoordinatesPicker";
import {Loads} from "react-loads";
import LocalLoader from "../../components/common/LocalLoader";

const { Option } = Select;

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

const StyledInput = styled(Input)`
  width: 30rem;
`

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 14,
    },
}

const MAX_RESERVABLES = 24
const CURRENCY_LIST = ["USD", "EUR"]
const HAL_ADAPTER_TYPES = ["station-v1", "station-v1b", "station-v2", "station-v2b", "station-simulation"]
const LOCATION_TYPE_STATION = "station"
const LOCATION_TYPE_PRIVATE_STATION = "private-station"
const LOCATION_TYPE_CONTAINER = "container"

const OPERATING_MODE_PUBLIC = "public"
const OPERATING_MODE_PRIVATE = "private"
const OPERATING_MODE_PUBLIC_RESERVED = "public_reserved"
// const OPERATING_MODE_PUBLIC_PARTITIONED = "public_partitioned"
const OPERATING_MODE_LIST = [OPERATING_MODE_PUBLIC,OPERATING_MODE_PRIVATE, OPERATING_MODE_PUBLIC_RESERVED] //TODO: Add "public_partitioned"

export default class CreateLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null,
      infoMessage: null,
      isCreated: false,
      loading: false,
      address: '',
      countryCode:'',
      balenaDeviceId: '',
      stationType: LOCATION_TYPE_STATION,
      reservables: 12,
      halAdapterType: HAL_ADAPTER_TYPES[2],
      balenaApp: 'staging',
      isPickerOpen: false,
      isContainer: false,
      partnersId: [""],
      currency: CURRENCY_LIST[0],
      remoteLockDevicesUUID:["","","",""],
      isMoveToAnotherApplication: false,
      coordinates: {
        lat: 0,
        lng: 0
      },
      operatingMode: OPERATING_MODE_PUBLIC,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePick = this.handlePick.bind(this)
    this.loadBalendDevices = this.loadBalendDevices.bind(this)
    this.loadPartners = this.loadPartners.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
  }

  handleChange(field, value, arrayIndex=0) {
      const {operatingMode, remoteLockDevicesUUID} = this.state
    switch (field) {
        case 'remoteLockDevicesUUID':
            let items = [...this.state.remoteLockDevicesUUID];
            let item = {...items[arrayIndex]};
            item = value;
            items[arrayIndex] = item;
            this.setState(update(this.state, {
                remoteLockDevicesUUID: {$set: items},
            }))
            break
        case 'stationType':
            let reservablesCount = 12
            let isContainer = false
            let operatingModeValue = operatingMode
            let remoteLockDevicesUUIDValue = remoteLockDevicesUUID
            if (value === LOCATION_TYPE_CONTAINER) {
                reservablesCount = 4
                isContainer = true
                remoteLockDevicesUUIDValue = ["","","",""]
                operatingModeValue = OPERATING_MODE_PUBLIC
            }
            this.setState(update(this.state, {
                stationType: {$set: value},
                reservables: {$set: reservablesCount},
                isContainer: {$set: isContainer},
                operatingMode: {$set: operatingModeValue},
                remoteLockDevicesUUIDValue: {$set: remoteLockDevicesUUIDValue},
            }))
            break
        case 'coordinates.lat':
            value = (value < -90)? -90: value
            value = (value > 90)? 90: value
            this.setState(update(this.state, {
                coordinates: {lat:{$set: value}}
            }))
            break
        case 'coordinates.lng':
            value = (value < -180)? -180: value
            value = (value > 180)? 180: value
            this.setState(update(this.state, {
                coordinates: {lng:{$set: value}}
            }))
            break
        default:
            this.setState(update(this.state, {
                errorMessage: { $set: null },
                [field]: { $set: value }
            }))
    }
  }

  handlePick(coordinates){
    this.setState(update(this.state, {
       isPickerOpen:  { $set: false },
        coordinates: {lat:{$set: coordinates.lat}, lng:{$set: coordinates.lng} }
    }))
  }

  async handleSubmit(e) {
      e.preventDefault()
      const { countryCode, address, coordinates, balenaDeviceId,
          stationType, halAdapterType, isMoveToAnotherApplication,
          balenaApp, reservables, remoteLockDevicesUUID, currency,
          partnersId, operatingMode} = this.state
      let resp
      this.setState(update(this.state, {
          errorMessage: { $set: null },
          infoMessage: { $set: null },
          isCreated: { $set: false },
          loading: { $set: true }
      }))

      try {
          resp = await consoleCreateLocation({
              address: address,
              countryCode: countryCode,
              coordinates: coordinates,
              balenaDeviceId: balenaDeviceId,
              stationType: stationType,
              halAdapterType: halAdapterType,
              isMoveToAnotherApplication: isMoveToAnotherApplication,
              balenaApp: balenaApp,
              reservables: reservables,
              remoteLockDevicesUUID: remoteLockDevicesUUID,
              currency: currency,
              partnersId: partnersId,
              operatingMode: operatingMode
          })
          this.setState(update(this.state, {
              loading: { $set: false }
          }))
          if (resp.info !== "") {
              this.setState(update(this.state, {
                  infoMessage: { $set: resp.info },
                  isCreated: { $set: true },
              }))
          }
      } catch (err) {
          const errorMessage = err.message
          this.setState(update(this.state, {
              errorMessage: { $set: errorMessage },
              loading: { $set: false },
              isMoveToAnotherApplication: { $set: false },
          }))
      }
  }

  async loadBalendDevices() {
      return consoleGetBalenaDevices()
  }

  isFormValid() {
      let isValid = !this.state.isCreated
      isValid = isValid && (this.state.countryCode.length > 0)
      isValid = isValid && (this.state.address.length > 0)
      switch(this.state.stationType) {
          case LOCATION_TYPE_STATION:
              isValid = isValid && (this.state.balenaDeviceId !== "")
              isValid = isValid && (this.state.operatingMode !== "")
              break;
          case LOCATION_TYPE_PRIVATE_STATION:
              isValid = isValid && (this.state.balenaDeviceId !== "")
              isValid = isValid && (this.state.partnersId.length > 0)
              isValid = isValid && (this.state.operatingMode !== "")
              break;
          case LOCATION_TYPE_CONTAINER:
              for (let i=0; i < this.state.remoteLockDevicesUUID.length; i++) {
                  isValid = isValid && (this.state.remoteLockDevicesUUID[i].length > 0)
              }
              break;
          default:
              break;
      }

      return isValid
  }

  async loadPartners() {
        return (await consolePartnersInspect()).partners
  }

  render() {
    const {
        infoMessage, errorMessage, loading,
        isPickerOpen, countryCode, address, coordinates,
        isMoveToAnotherApplication, balenaApp, reservables,
        isContainer, remoteLockDevicesUUID, currency, stationType, operatingMode} = this.state
    return (
      <StyledContainer>

          <Fragment>
              {infoMessage && <Alert message="Location created successfully" type='success' description={infoMessage} showIcon/>}
              {errorMessage && <Alert message="Failed to create location" type='error' description={errorMessage} showIcon/>}
          </Fragment>
          <StyledForm layout='horizontal' onSubmit={this.handleSubmit} formlayout={formItemLayout}>
              <Form.Item label='Country Code' rules={[{ required: true }]}>
                  <StyledInput disabled={loading} onChange={(e) => this.handleChange('countryCode', e.target.value)} placeholder='Country Code' value={countryCode}/>
              </Form.Item>
              <Form.Item label='Address' rules={[{ required: true }]}>
                  <StyledInput disabled={loading} onChange={(e) => this.handleChange('address', e.target.value)} placeholder='Address' value={address}/>
              </Form.Item>

              {isPickerOpen && <CoordinatesPicker coordinates={coordinates} onCancel={() => this.handleChange('isPickerOpen', false)} onPick={this.handlePick}/>}
              <Input.Group compact>
                  <Row gutter={12}>
                      <Col span={8}>
                          <Form.Item label='Latitude' rules={[{ required: true }]}>
                              <InputNumber max={90} min={-90} disabled={loading} onChange={(e) => this.handleChange('coordinates.lat', e)} placeholder='Latitude' value={coordinates.lat}/>
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item label='Longitude' rules={[{ required: true }]}>
                              <InputNumber max={180} min={-180} disabled={loading} onChange={(e) => this.handleChange('coordinates.lng', e)} placeholder='Longitude' value={coordinates.lng}/>
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item>
                              <label>  &nbsp; </label>
                              <Button onClick={() =>  this.handleChange('isPickerOpen', true)}>Pick Coordinates</Button>
                          </Form.Item>
                      </Col>
                  </Row>
              </Input.Group>

              <Input.Group>
                  <Row gutter={[16, 16]}>
                      <Col span={3}>
                          <Form.Item label='Station type' rules={[{ required: true }]}>
                              <Select defaultValue={LOCATION_TYPE_STATION} onChange={(value) => this.handleChange('stationType', value)} style={{ width: '10rem' }}>
                                  <Option key={LOCATION_TYPE_CONTAINER} value={LOCATION_TYPE_CONTAINER}>{LOCATION_TYPE_CONTAINER}</Option>
                                  <Option key={LOCATION_TYPE_STATION} value={LOCATION_TYPE_STATION}>{LOCATION_TYPE_STATION}</Option>
                                  <Option key={LOCATION_TYPE_PRIVATE_STATION} value={LOCATION_TYPE_PRIVATE_STATION}>{LOCATION_TYPE_PRIVATE_STATION}</Option>
                              </Select>
                          </Form.Item>
                      </Col>
                      <Col span={3}>
                          <Form.Item label='Adapter type' rules={[{ required: true }]}>
                              <Select defaultValue={HAL_ADAPTER_TYPES[2]} onChange={(value) => this.handleChange('halAdapterType', value)} style={{ width: '10rem' }}>
                                  { HAL_ADAPTER_TYPES.map((el, i) =>
                                      <Option key={el} value={el}>{el}</Option>
                                  )}
                              </Select>
                          </Form.Item>
                      </Col>
                      {!isContainer &&
                          <Col span={3}>
                              <Form.Item label='Operating mode' rules={[{required: true}]}>
                                  <Select defaultValue={OPERATING_MODE_LIST[0]}
                                          onChange={(value) => this.handleChange('operatingMode', value)}
                                          style={{width: '10rem'}}>
                                      {OPERATING_MODE_LIST.map((el, i) =>
                                          <Option key={el} value={el}>{el}</Option>
                                      )}
                                  </Select>
                              </Form.Item>
                          </Col>
                      }
                  </Row>
              </Input.Group>

              {!isContainer &&
                <Form.Item label='Station reservables' >
                    <Select defaultValue={reservables} onChange={(value) => this.handleChange('reservables', value)} style={{ width: '10rem' }}>
                        {Array(MAX_RESERVABLES).fill(1).map((el, i) =>
                            <Option key={i+1} value={i+1}>{i+1}</Option>
                        )}
                    </Select>
                </Form.Item>
              }

              {isContainer &&
              <Input.Group compact>
                  <Row gutter={24}>
                      <Col span={6}>
                          <Form.Item label='Currency'>
                              <Select defaultValue={currency} onChange={(value) => this.handleChange('currency', value)} style={{ width: '5rem' }}>
                                  { CURRENCY_LIST.map((el, i) =>
                                      <Option key={el} value={el}>{el}</Option>
                                  )}
                              </Select>
                          </Form.Item>
                      </Col>
                      <Col span={24}>
                          {remoteLockDevicesUUID.map((el, i) =>
                              <Form.Item key={'remoteLockDevicesUUID'+i} label={'Remote lock device UUID #'+(i+1)} rules={[{ required: true }]}>
                                  <StyledInput key={'remoteLockDevicesUUID'+i} onChange={(e) => this.handleChange('remoteLockDevicesUUID', e.target.value, i)} value={remoteLockDevicesUUID[i]}/>
                              </Form.Item>
                          )}
                      </Col>
                  </Row>
              </Input.Group>
              }
              {stationType !== LOCATION_TYPE_CONTAINER &&
              <Fragment>
                  {(operatingMode === OPERATING_MODE_PRIVATE || operatingMode === OPERATING_MODE_PUBLIC_RESERVED) &&
                    <Fragment>
                        <Form.Item label='Partner'>
                            <Loads fn={this.loadPartners} context="partners">
                                {({ response, error, isPending, isResolved, isRejected }) => (
                                    <Fragment>
                                        {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                                        {isResolved && <Select mode="multiple" allowClear onChange={(value) => this.handleChange('partnersId', value)} style={{ width: '30rem' }}>
                                            {response && response.map(item => (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name} - {item.id}
                                                </Option>
                                            ))}
                                        </Select> }
                                        {isRejected && <Alert type='error' message={error.message}/>}
                                    </Fragment>
                                )}
                            </Loads>
                        </Form.Item>
                    </Fragment>
                  }

                  <Form.Item label='Balena Device' rules={[{ required: true }]}>
                      <Loads fn={this.loadBalendDevices} context="balenaDevices">
                          {({ response, error, isPending, isResolved, isRejected }) => (
                              <Fragment>
                                  {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                                  {isResolved && <Select onChange={(value) => this.handleChange('balenaDeviceId', value)} style={{ width: '30rem' }}>
                                      {response.devices && response.devices.map(item => (
                                          <Option key={item.id} value={item.id}>
                                              {item.name} - {item.id}
                                          </Option>
                                      ))}
                                  </Select> }
                                  {isRejected && <Alert type='error' message={error.message}/>}
                              </Fragment>
                          )}
                      </Loads>
                  </Form.Item>

                  <Form.Item label='Balena application' rules={[{ required: true }]}>
                      Move to another application? <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={isMoveToAnotherApplication} onChange={(value) => this.handleChange('isMoveToAnotherApplication', value)} />
                      &nbsp;
                      <Select disabled={!isMoveToAnotherApplication} defaultValue={balenaApp} onChange={(value) => this.handleChange('balenaApp', value)} style={{ width: '10rem' }}>
                          <Option key='prod' value='prod'>prod</Option>
                          <Option key='staging' value='staging'>staging</Option>
                          <Option key='diagnostics' value='diagnostics'>diagnostics</Option>
                      </Select>
                  </Form.Item>
              </Fragment>
              }
              <Form.Item validateStatus={errorMessage ? 'error' : null} help={errorMessage}>
                  <Button disabled={!this.isFormValid()} htmlType='submit' loading={loading} type='primary'>Create location</Button>
              </Form.Item>
          </StyledForm>
      </StyledContainer>
    )
  }
}