import { Statistic } from 'antd'
import { Map, Popup } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class LocationsHeatMap extends Component {
  static propTypes = {
    geoJson: PropTypes.object.isRequired
  }

  componentDidMount() {
    this.map = new Map({
      container: this.container,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-25.263746139378554, 43.503959523712325],
      zoom: 1.5
    })

    this.map.on('load', () => {
      this.map.addSource('data', {
        'type': 'geojson',
        'data': this.props.geoJson
      })

      this.map.addLayer(
        {
          'id': 'heat-map',
          'type': 'heatmap',
          'source': 'data',
          'maxzoom': 20,
          'paint': {
            'heatmap-weight': [
              'interpolate',
              ['linear'],
              ['get', 'total_sessions'],
              0,
              0,
              6,
              1
            ],
            'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              1,
              9,
              3
            ],
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)'
            ],
            'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              10,
              9,
              20
            ],
            'heatmap-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              1,
              20,
              0
            ]
          }
        },
        'waterway-label'
      )

      this.map.addLayer(
        {
          'id': 'points',
          'type': 'circle',
          'source': 'data',
          'minzoom': 10,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
              16,
              ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50]
            ],
            'circle-color': [
              'interpolate',
              ['linear'],
              ['get', 'total_sessions'],
              1,
              'rgba(33,102,172,0)',
              2,
              'rgb(103,169,207)',
              3,
              'rgb(209,229,240)',
              4,
              'rgb(253,219,199)',
              5,
              'rgb(239,138,98)',
              6,
              'rgb(178,24,43)'
            ],
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1,
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              0,
              8,
              1
            ]
          }
        },
        'waterway-label'
      )

      const popup = new Popup({
        closeButton: false,
        closeOnClick: false
      });

      this.map.on('mouseenter', 'points', e => {
        this.map.getCanvas().style.cursor = 'pointer'

        const coordinates = e.features[0].geometry.coordinates.slice()
        const totalSessions = e.features[0].properties.total_sessions
        const locationAddress = e.features[0].properties.location_address

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const container = document.createElement('div')
        ReactDOM.render(<Statistic title={locationAddress} value={totalSessions} precision={0} />, container)

        popup
          .setLngLat(coordinates)
          .setDOMContent(container)
          .addTo(this.map)
      })

      this.map.on('mouseleave', 'points', e => {
        this.map.getCanvas().style.cursor = ''
        popup.remove()
      })
    })
  }

  componentWillUnmount() {
    this.map.remove()
  }

  render() {
    return (
      <div ref={el => this.container = el} style={{width: '100%', height: '30rem'}}/>
    )
  }
}