import { Link } from '@reach/router'
import { Alert, DatePicker, Descriptions, Table, Typography } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import update from 'react-addons-update'
import styled from 'styled-components'
import { consoleUserReservationsInspect } from '../../../../logic/network'
import {formatCurrency, formatDate, googleMapsAddressUrl, stripePaymentIntentIdUrl } from '../../../../logic/utils'

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

const StyledMonthPicker = styled(DatePicker.MonthPicker)`
  margin: 0 24px 24px 0;
`

export default class JuicerAppUserReservations extends Component {
  static propTypes = {
    cognitoUser: PropTypes.object.isRequired
  }

  static columns = [
    {
      title: 'Reservation ID',
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>,
    },
    {
      title: 'Start',
      dataIndex: 'range.start',
      key: 'start',
      render: v => formatDate(v),
      width: 230
    },
    {
      title: 'End',
      dataIndex: 'range.end',
      key: 'end',
      render: v => formatDate(v),
      width: 230
    }
  ]

  static renderExpandedRow(row) {
    return (
      <Descriptions bordered size='small' column={1}>
        {row.locationId && <Descriptions.Item label='Location ID'><Link to={`/infrastructure/locations/${row.locationId}`}><StyledCode code>{row.locationId}</StyledCode></Link></Descriptions.Item>}
        {row.locationAddress && <Descriptions.Item label='Location Address'><a href={googleMapsAddressUrl(row.locationAddress)} target='_blank' rel='noopener noreferrer'>{row.locationAddress}</a></Descriptions.Item>}
        {row.price && <Descriptions.Item label='Price'>{formatCurrency(row.price, row.currency)}</Descriptions.Item>}
        {row.price && <Descriptions.Item label='Discount'>{row.discount ? `${row.discount}%` : 'None'}</Descriptions.Item>}
        {row.access && row.access.type === 'pinCode' && <Descriptions.Item label='Access'>Pin Code ({row.access.pinCode})</Descriptions.Item>}
        {row.stripePaymentIntentId && <Descriptions.Item label='Stripe Payment Intent ID'><a href={stripePaymentIntentIdUrl(row.consoleStripePaymentIntentId)}>{row.consoleStripePaymentIntentId}</a></Descriptions.Item>}
        <Descriptions.Item label='Created At'>{formatDate(row.consoleCreatedAt)}</Descriptions.Item>
      </Descriptions>
    )
  }

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      yearMonth: moment()
    }
  }

  async loadData(cognitoUser, yearMonth) {
    return (await consoleUserReservationsInspect(cognitoUser.Username, yearMonth.clone().startOf('month'), yearMonth.clone().endOf('month'), true)).reservations
  }

  handleChange(yearMonth) {
    this.setState(update(this.state, {
      yearMonth: { $set: yearMonth }
    }))
  }

  render() {
    const { cognitoUser } = this.props
    const { yearMonth } = this.state

    return (
      <Loads fn={this.loadData}  context='infrastructure/juicer-app-users/juicer-app-user/juicer-app-user-reservations' variables={[cognitoUser, yearMonth]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            {!isRejected &&
            <Fragment>
              <StyledMonthPicker format='MMMM YYYY' placeholder='Select Month' onChange={this.handleChange} value={yearMonth} />
              <br/>
              <Table expandRowByClick={true} rowKey={r => r.id} columns={JuicerAppUserReservations.columns} dataSource={!isPending && !isReloading && isResolved && response ? response : []} expandedRowRender={JuicerAppUserReservations.renderExpandedRow} loading={isPending} pagination={false} size='middle' scroll={{y: 'calc(100vh - 19rem)'}}/>
            </Fragment>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}