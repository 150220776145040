import { Alert } from 'antd'
import React,  { Component } from 'react'
import styled from 'styled-components'
import TextLookup from '../../components/common/TextLookup'
import { consoleUserInspect } from '../../logic/network'

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
`

const StyledAlert = styled(Alert)`
  text-align: center;
`

export default class JuicerAppUsers extends Component {
  constructor(props) {
    super(props)

    this.handleLookup = this.handleLookup.bind(this)
  }

  async handleLookup(query) {
    try {
      const resp = await consoleUserInspect(query)
      this.props.navigate(`/infrastructure/juicer-app-users/${resp.userId}`)
    } catch (err) {
      if (err.status === 400 || err.status === 404) {
        throw new Error('Juicer App user not found.')
      }
      throw err
    }
  }

  render() {
    return (
      <StyledContainer>
        <StyledAlert message='Look up Juicer App users by ID (e.g. 2a8d60d1-b5f6...) or phone number (e.g. +12223334444).'/>
        <br/>
        <TextLookup action='Look Up' icon='user' onSubmit={this.handleLookup} placeholder='User ID or Phone Number' width='20rem'/>
      </StyledContainer>
    )
  }
}