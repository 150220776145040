import { Redirect, Router } from '@reach/router'
import { Hub } from 'aws-amplify'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import { Loads } from 'react-loads'
import GlobalError from './components/common/GlobalError'
import GlobalLoader from './components/common/GlobalLoader'
import GlobalNotFound from './components/common/GlobalNotFound'
import GlobalUnauthorized from './components/common/GlobalUnauthorized'
import Root from './containers/Root'
import SignIn from './containers/SignIn'
import Stations from './containers/analytics/Stations'
import Locations from './containers/infrastructure/Locations'
import CreateLocation from './containers/infrastructure/CreateLocation'
import JuicerAppUsers from './containers/infrastructure/JuicerAppUsers'
import JuicerAppUser from './containers/infrastructure/juicer-app-users/JuicerAppUser'
import Partners from './containers/infrastructure/Partners'
import Partner from './containers/infrastructure/partners/Partner'
import Location from './containers/infrastructure/locations/Location'
import PendingInvoices from './containers/operations/PendingInvoices'
import Provisioning from './containers/operations/Provisioning'
import OpenAlerts from './containers/operations/OpenAlerts'
import Settings from './containers/operations/Settings'
import { getCurrentUserInfo } from './logic/auth'
import { bugsnagClient } from './logic/config'
import { UnauthorizedError } from './logic/errors'
import { consoleAuthenticationVerify } from './logic/network'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
    this.handleAuthEvent = this.handleAuthEvent.bind(this)

    this.state = {
      refreshCount: 0
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.handleAuthEvent)
  }

  componentWillUnmount() {
    Hub.remove('auth', this.handleAuthEvent)
  }

  async handleAuthEvent(e) {
    if (e.payload && (e.payload.event === 'signIn' || e.payload.event === 'signOut')) {
      if (e.payload.event === 'signIn') {
        bugsnagClient.user = {
          email: e.payload.data.attributes.email
        }
      }

      if (e.payload.event === 'signOut') {
        bugsnagClient.user = null
      }

      this.setState(update(this.state, {
        refreshCount: { $apply: ((x) => x + 1) }
      }))
    }
  }

  async loadData() {
    const data = {
      userInfo: await getCurrentUserInfo(),
      partnerInfo: null
    }

    if (data.userInfo !== null) {
      data.partnerInfo = await consoleAuthenticationVerify(data.userInfo.attributes.email)

      bugsnagClient.user = {
        email: data.userInfo.attributes.email
      }
    }

    return data
  }

  render() {
    return (
      <Loads fn={this.loadData}  context='app' variables={[this.state.refreshCount]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {isPending  && <GlobalLoader/>}
            {isResolved && !response.userInfo && <SignIn/>}
            {isResolved && response.userInfo && (
              <Router>
                <Root path='/' auth={response}>
                  <Redirect from='/' to='/infrastructure/locations' noThrow/>
                  <Redirect from='infrastructure' to='/infrastructure/locations' noThrow/>
                  <Redirect from='analytics' to='/analytics/stations' noThrow/>
                  <Stations path='analytics/stations'/>
                  <Locations path='infrastructure/locations'/>
                  <CreateLocation path='infrastructure/location/create'/>
                  <Location path='infrastructure/locations/:locationId'/>
                  <JuicerAppUsers path='infrastructure/juicer-app-users'/>
                  <JuicerAppUser path='infrastructure/juicer-app-users/:userId'/>
                  <Partners path='infrastructure/partners'/>
                  <Partner path='infrastructure/partners/:partnerId'/>
                  <PendingInvoices path='operations/pending-invoices'/>
                  <Provisioning path='operations/provisioning'/>
                  <OpenAlerts path='operations/open-alerts'/>
                  <Settings path='operations/settings'/>
                  <GlobalNotFound default/>
                </Root>
              </Router>
            )}
            {isRejected && (error instanceof UnauthorizedError ? <GlobalUnauthorized email={error.metadata.email}/> : <GlobalError message={error.message}/>)}
          </Fragment>
        )}
      </Loads>
    )
  }
}


