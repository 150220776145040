import { Alert, Tabs } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../../../components/common/LocalLoader'
import PartnerOverview from '../../../components/infrastructure/partners/partner/PartnerOverview'
import PartnerInvoices from '../../../components/infrastructure/partners/partner/PartnerInvoices'
import PartnerPrivateLocations from '../../../components/infrastructure/partners/partner/PartnerPrivateLocations'
import { consolePartnerInspect } from '../../../logic/network'

const StyledTabsContainer = styled.div`
  & .ant-tabs-bar {
    padding-left: 24px !important;
  }
 
  & .ant-tabs-tab {
    margin-right: 8px !important;
  }
  
  & .ant-tabs-tabpane {
    padding-top: 0.5rem;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 12.5rem);
`

const StyledAlert = styled(Alert)`
  margin: 0 24px;
`

export default class Partner extends Component {
  static propTypes = {
    partnerId: PropTypes.string
  }

  async loadData(partnerId) {
    try {
      return await consolePartnerInspect(partnerId, {
        includeInvoices: true
      })
    } catch (err) {
      if (err.status === 400 || err.status === 404) {
        throw new Error('Partner not found.')
      }
      throw err
    }
  }

  render() {
    const { partnerId } = this.props
    return (
      <Loads fn={this.loadData}  context='infrastructure/partners/partner' variables={[partnerId]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved &&
              <StyledTabsContainer>
                <Tabs type='card'>
                  <Tabs.TabPane key='partner' tab='Partner'>
                    <StyledContainer>
                      <PartnerOverview partner={response.partner}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                  <Tabs.TabPane key='invoices' tab='Invoices'>
                    <StyledContainer>
                      <PartnerInvoices invoices={response.invoices}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                  <Tabs.TabPane key='locations' tab='Partner locations'>
                    <StyledContainer>
                      <PartnerPrivateLocations partner={response.partner}/>
                    </StyledContainer>
                  </Tabs.TabPane>
                </Tabs>
              </StyledTabsContainer>
            }
            {isRejected && <StyledAlert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}