import { DatePicker, Select, Tabs } from 'antd'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import update from 'react-addons-update'
import styled from 'styled-components'
import StationsSummary from './stations/StationsSummary'
import StationsInsights from './stations/StationsInsights'
import { consolePartnersInspect } from '../../logic/network'

const StyledMonthPicker = styled(DatePicker.MonthPicker)`
  margin: 0 24px 24px 24px;
`

const StyledTabsContainer = styled.div`
  & .ant-tabs-bar {
    padding-left: 24px !important;
  }
 
  & .ant-tabs-tab {
    margin-right: 8px !important;
  }
  
  & .ant-tabs-tabpane {
    padding-top: 0.5rem;
  }
`

const StyledSelect = styled(Select)`
  width: 10rem;
`

const StyledContent = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 15rem);
`


export default class Stations extends Component {
  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this)
    this.handlePartnerIdChange = this.handlePartnerIdChange.bind(this)

    this.state = {
      yearMonth: moment(),
      partnerId: null
    }
  }

  async loadData() {
    return (await consolePartnersInspect()).partners
  }

  handleYearMonthChange(yearMonth) {
    this.setState(update(this.state, {
      yearMonth: { $set: yearMonth }
    }))
  }

  handlePartnerIdChange(partnerId) {
    this.setState(update(this.state, {
      partnerId: { $set: partnerId }
    }))
  }

  render() {
    const { yearMonth, partnerId } = this.state

    return (
      <Fragment>
        <StyledMonthPicker format='MMMM YYYY' placeholder='Select Month' onChange={this.handleYearMonthChange} value={yearMonth} />
        <Loads fn={this.loadData}  context='analytics/stations'>
          {({ response, error, isPending, isResolved, isRejected }) => (
            <StyledSelect value={partnerId} loading={isPending} onChange={this.handlePartnerIdChange}>
              <Select.Option value={null}>All Partners</Select.Option>
              {isResolved && response.map(p => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)}
            </StyledSelect>
          )}
        </Loads>
        <StyledTabsContainer>
          <Tabs type='card'>
            <Tabs.TabPane key='summary' tab='Summary'>
              <StyledContent><StationsSummary yearMonth={yearMonth} partnerId={partnerId}/></StyledContent>
            </Tabs.TabPane>
            <Tabs.TabPane key='insights' tab='Insights'>
              <StyledContent><StationsInsights yearMonth={yearMonth} partnerId={partnerId}/></StyledContent>
            </Tabs.TabPane>
          </Tabs>
        </StyledTabsContainer>
      </Fragment>
    )
  }
}
